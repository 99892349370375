<template>
  <div>
    <loginview v-if="islogin || isreg" />

    <div class="d-flex w-100 h-100 overflow-hidden">
      <maintop1 />
      <top_app />
      <div
        class="d-flex w-100 h-100 overflow-hidden flex-column"
        id="home"
        :class="flag_app ? 'open_app' : 'close_app'"
      >
        <maintop2 ref="getbalance_home" />
        <div
          class="d-flex overflow-x-hidden overflow-y-auto"
          style="height: 100vh; background-color: #fff"
          id="content_page"
        >
          <div style="width: 200px" class="flag_width_200"></div>
          <div
            class="mb-5 content_page m-0 mx-auto right_ann_close"
            :class="Id == 'club' ? 'content_p_0_page' : ''"
          >
            <div class="h-100">
              <div
                :class="Id == 'promo' ? 'p-0' : ''"
                class="d-flex justify-content-between flex-column align-items-center h-100"
              >
                <div
                  class="content_div"
                  :style="Id == 'store' ? 'height: 100%;' : ''"
                >
                  <div
                    style="width: 100%"
                    :style="Id == 'store' ? 'height: 100%;' : ''"
                    :class="Id == 'store' ? 'mb-5' : ''"
                  >
                    <!-- <mainsportview v-if="Id == 'main_sport'" /> -->

                    <storeview v-if="Id == 'store'" />
                    <div v-show="flag_banner_mar">
                      <div class="carousel-live-feed-holder">
                        <div class="web-banner">
                          <banner />
                        </div>
                      </div>
                      <marqueeview />
                    </div>

                    <mainview v-if="Id == ''" />
                    <game_page v-if="Id == 'slot' || Id == 'casino'" />
                    <promotion v-if="Id == 'promo'" />
                    <!-- <vipintro v-if="Id == 'VIP_intro'" /> -->
                    <!-- <share v-if="Id == 'share'" /> -->
                    <home2 v-if="Id == 'ME'" />
                    <home3 v-if="Id == 'BO'" />
                    <homehelp v-if="Id == 'help'" />
                    <member v-if="Id == 'VIP'" />
                    <!-- <home_vip v-if="Id == 'home_vip'" /> -->
                    <!-- <club v-if="Id == 'club'" /> -->
                    <jackpot_game v-if="Id == 'jackpot_game'" />
                    <investview v-if="Id == 'invest'" />
                    <service_center v-if="Id == 'service_center'" />
                    <self_center v-if="Id == 'self_center'" />
                    <!-- <ecology v-if="Id == 'ecology'" />
                    <company_introduce v-if="Id == 'company_introduce'" /> -->
                  </div>
                </div>
                <footertwo />
              </div>
            </div>
          </div>
        </div>
        <prewindow></prewindow>
      </div>
    </div>

    <Footer />
    <newmessage ref="newmess"></newmessage>
    <!-- <right_bar /> -->
    <drawview v-if="flag_wheel" @close_wheel_main="close_wheel_fun"></drawview>
    <signupview v-if="flag_sign" @close_sign_main="close_sign_fun"></signupview>
    <extraview
      v-if="flag_extra"
      @close_extra_main="close_extra_fun"
    ></extraview>
    <!-- <interview v-if="flag_inter" @close_inter_main="close_inter_fun" /> -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import maintop1 from "./Main_top1.vue";
import maintop2 from "./Main_top2.vue";
import mainview from "./main.vue";
import Footer from "./Home/Footer.vue";
import footertwo from "./Home/footer_two.vue";
import Prewindow from "./Home/prewindow.vue";
import loginview from "./Login.vue";
// import mainsportview from "./main_sport.vue";
import storeview from "./shop_store.vue";
import game_page from "./game_page.vue";
import promotion from "./Promotion.vue";
// import vipintro from "./vip_introduce.vue";
// import share from "./new_share.vue";
import home2 from "./Home2.vue";
import home3 from "./Home3.vue";
import homehelp from "./footer_help.vue";
import member from "./Member.vue";
import newmessage from "./new_Message.vue";
// import right_bar from "./right_bar.vue";
import top_app from "./top_app.vue";
import drawview from "./draw.vue";
import signupview from "./sign_up.vue";
import extraview from "./extra.vue";
import banner from "./Home/Banner.vue";
import marqueeview from "./marquee.vue";
// import home_vip from "./home_vip.vue";
// import club from "./club_home.vue";
import jackpot_game from "./jackpot_game.vue";
import investview from "./invest.vue";
import service_center from "./service_center.vue";
import self_center from "./self_center.vue";
// import ecology from "./ecology.vue";
// import company_introduce from "./company_introduce.vue";
// import interview from "./inter.vue";
export default {
  name: "Home",
  data() {
    return {
      isMobile: this.$Utils.isMobile(),
    };
  },
  components: {
    maintop1,
    maintop2,
    mainview,
    Footer,
    footertwo,
    Prewindow,
    loginview,
    // mainsportview,
    storeview,
    game_page,
    promotion,
    // vipintro,
    // share,
    home2,
    home3,
    homehelp,
    member,
    newmessage,
    // right_bar,
    top_app,
    drawview,
    signupview,
    extraview,
    banner,
    marqueeview,
    // home_vip,
    // club,
    jackpot_game,
    investview,
    service_center,
    self_center,
    // ecology,
    // company_introduce,
    // interview,
  },
  computed: {
    ...mapGetters([
      "watch_newWindowurl",
      "islogin",
      "isreg",
      "flag_app",
      "flag_wheel",
      "flag_sign",
      "flag_extra",
      "flag_inter",
    ]),
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
    flag_banner_mar() {
      if (
        this.isMobile != "pc端" &&
        (this.Id == "" || this.Id == "slot" || this.Id == "casino")
      ) {
        return true;
      } else if (this.isMobile == "pc端" && this.Id == "") {
        return true;
      } else return false;
    },
  },
  methods: {
    ...mapActions(["getBalance"]),
    close_wheel_fun: function () {
      this.$store.commit("flag_wheel", false);
    },
    close_sign_fun: function () {
      this.$store.commit("flag_sign", false);
    },
    close_extra_fun: function () {
      this.$store.commit("flag_extra", false);
    },
    close_inter_fun: function () {
      this.$store.commit("flag_inter", false);
    },
  },
  created() {},
  watch: {
    watch_newWindowurl: function (newval) {
      if (newval == "close") {
        this.getBalance();
        this.$refs.newmess.msg("30");
      }
    },
    islogin() {
      if (this.islogin) {
        this.$store.dispatch("logout_public");
      }
    },
    isreg() {
      if (this.isreg) {
        this.$store.dispatch("logout_public");
      }
    },
  },
  mounted() {},
};
</script>
