<template>
  <div v-if="isaddcard">
    <div class="finance_page daddbank_temp__page">
      <div class="finance_card">
        <div class="finance_close" @click="this.$emit('fun_flag_page', false)">
          ×
        </div>
        <div v-if="!flag_two">
          <div class="finance_title">
            {{ $t("text_d_addbank11") }}
          </div>

          <form method="POST" @submit="step1">
            <div class="finance_main">
              <div class="finance_deposit" style="margin-top: 1rem">
                <div>
                  <p class="text-center fw-bold">
                    {{ $t("text_d_addbank1") }}
                  </p>
                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <label class="text-nowrap me-2">{{
                      $t("text_d_addbank2")
                    }}</label>
                    <select v-model="bankname">
                      <option v-for="l in list" :key="l" :value="l">
                        {{ l }}
                      </option>
                    </select>
                    <!-- <input
                      type="text"
                      :placeholder="$t('text_et')"
                      v-model="bankname"
                      maxlength="20"
                      minlength="1"
                      required="required"
                    /> -->
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-3"
                  >
                    <label class="text-nowrap me-2">{{
                      $t("text_d_addbank3")
                    }}</label
                    ><input
                      type="text"
                      :placeholder="$t('text_et')"
                      v-model="subbankname"
                      maxlength="30"
                      minlength="1"
                      required="required"
                    />
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-3"
                  >
                    <label class="text-nowrap me-2">{{
                      $t("text_d_addbank4")
                    }}</label
                    ><input
                      type="text"
                      :placeholder="$t('text_bank_1')"
                      v-model="bankname_user"
                      maxlength="10"
                      minlength="1"
                      required="required"
                    />
                  </div>
                  <div class="d-flex align-items-center mt-2">
                    <p
                      class="fw-bold mb-0"
                      v-if="flag_bankname"
                      style="color: crimson; margin-left: 80px"
                    >
                      {{ $t("text_bank_1") }}
                    </p>
                  </div>
                  <div
                    class="d-flex justify-content-between align-items-center mt-3"
                  >
                    <label class="text-nowrap me-2">{{
                      $t("text_d_addbank5")
                    }}</label
                    ><input
                      type="text"
                      :placeholder="$t('text_bank_2')"
                      v-model="banknum"
                      maxlength="20"
                      minlength="6"
                      required="required"
                    />
                  </div>
                  <div class="d-flex align-items-center mt-2">
                    <p
                      class="fw-bold mb-0"
                      v-if="flag_banknum"
                      style="color: crimson; margin-left: 80px"
                    >
                      {{ $t("text_d_addbank20") }}
                    </p>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <label class="text-nowrap me-2">{{
                      $t("text_d_addbank6")
                    }}</label>

                    <div class="d-flex align-items-center">
                      <button
                        class="border-0 text-white p-2 w-100 bg_cof me-2"
                        @click="uploadImages"
                      >
                        <span>{{ $t("text_d_addbank7") }}</span>
                      </button>
                      <img v-if="bankimage" :src="bankimage" width="200" />
                      <input
                        ref="inputDOM"
                        type="file"
                        accept=".jpg, .jpeg"
                        style="z-index: -100; opacity: 0; width: 0"
                        @change="fileSelected"
                      />
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-2">
                    <p
                      class="fw-bold mb-0"
                      v-if="error_flag"
                      style="color: crimson; margin-left: 80px"
                    >
                      {{ $t("text_d_addbank10") }}
                    </p>
                  </div>
                </div>

                <p
                  class="text-center fw-bolder mt-2 tips_bank"
                  style="white-space: pre-line"
                >
                  {{ $t("text_d_addbank9_1") + $t("text_d_addbank9") }}<br />{{
                    $t("text_d_addbank9_2")
                  }}<br />{{ $t("text_d_addbank17") }}<br />{{
                    $t("text_d_addbank18")
                  }}
                </p>
                <div class="col-10 text-white mx-auto my-3">
                  <button
                    class="border-0 text-white p-2 w-100 bg_light_green_btn"
                  >
                    <span>{{ $t("text_d_addbank8") }}</span>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div v-if="flag_two" class="flag_two_div">
          <div class="finance_title">
            {{ $t("text_d_addbank12") }}
          </div>

          <div class="finance_main">
            <div class="finance_deposit" style="margin-top: 1rem">
              <div>
                <div class="d-flex align-items-center flex-wrap">
                  <button
                    class="border-0 text-white p-2 bg_light_green_btn me-4"
                    @click="step_back()"
                  >
                    <span>{{ $t("text_d_addbank16") }}</span>
                  </button>
                  <div class="d-flex flex-column mt-3">
                    <p class="text-center fw-bold mb-0">
                      {{ $t("text_d_addbank13") }}
                    </p>
                    <p class="text-center fw-bold">
                      {{ $t("text_d_addbank14") }}
                    </p>
                  </div>
                </div>
                <div class="two_step_b p-3">
                  <div>
                    <label class="text-nowrap">{{
                      $t("text_d_addbank2")
                    }}</label
                    >：
                    <label class="text-nowrap">{{ bankname }}</label>
                  </div>
                  <div class="mt-3">
                    <label class="text-nowrap">{{
                      $t("text_d_addbank3")
                    }}</label
                    >：
                    <label class="text-nowrap">{{ subbankname }}</label>
                  </div>
                  <div class="mt-3">
                    <label class="text-nowrap">{{
                      $t("text_d_addbank4")
                    }}</label
                    >：
                    <label class="text-nowrap">{{ bankname_user }}</label>
                  </div>
                  <div class="mt-3">
                    <label class="text-nowrap">{{
                      $t("text_d_addbank5")
                    }}</label
                    >：
                    <label class="text-nowrap">{{ banknum }}</label>
                  </div>
                  <div class="d-flex align-items-center mt-3">
                    <label class="text-nowrap">{{
                      $t("text_d_addbank6")
                    }}</label>
                    ：
                    <div>
                      <img v-if="bankimage" :src="bankimage" width="200" />
                    </div>
                  </div>
                  <div class="d-flex align-items-center mt-2">
                    <p
                      class="fw-bold mb-0"
                      v-if="error_flag"
                      style="color: crimson; margin-left: 80px"
                    >
                      {{ $t("text_d_addbank10") }}
                    </p>
                  </div>
                </div>
              </div>

              <p
                class="text-center fw-bolder mt-2 tips_bank"
                style="white-space: pre-line"
              >
                {{ $t("text_d_addbank9_1") + $t("text_d_addbank9") }}<br />{{
                  $t("text_d_addbank9_2")
                }}<br />{{ $t("text_d_addbank17") }}<br />{{
                  $t("text_d_addbank18")
                }}
              </p>
              <div class="col-10 text-white mx-auto my-3">
                <button
                  class="border-0 text-white p-2 w-100 bg_light_green_btn"
                  @click="step2()"
                >
                  <span>{{ $t("text_d_addbank15") }}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";

export default {
  name: "adddepositbank",
  data() {
    return {
      flag_two: false,
      bankimage: "",
      error_flag: false,
      bankname: "",
      subbankname: "",
      bankname_user: "",
      banknum: "",
      flag_banknum: false,
      flag_bankname: false,
      list: [],
    };
  },
  components: {},
  computed: {
    ...mapGetters(["isaddcard"]),
  },
  methods: {
    uploadImages: function (e) {
      e.preventDefault();
      this.$refs.inputDOM.click();
    },
    fileSelected(e) {
      const file = e.target.files.item(0);
      const reader = new FileReader();
      if (this.bankimage == "" || this.bankimage == null) {
        if (file.type != "image/jpg" && file.type != "image/jpeg") {
          this.error_flag = true;
          this.bankimage = "";
        } else {
          this.error_flag = false;
          reader.addEventListener("load", this.imageLoaded);
          reader.readAsDataURL(file);
        }
      } else {
        this.error_flag = true;
        this.bankimage = "";
      }
    },
    imageLoaded(e) {
      this.bankimage = e.target.result;
    },
    step1(e) {
      e.preventDefault();
      if (this.bankimage == "" || this.bankimage == null)
        this.error_flag = true;
      if (!this.vali_cardnum(this.banknum)) this.flag_banknum = true;
      else this.flag_banknum = false;
      if (!this.vali_name(this.bankname_user)) this.flag_bankname = true;
      else this.flag_bankname = false;
      if (!this.error_flag && !this.flag_banknum && !this.flag_bankname)
        this.flag_two = true;
    },
    step2() {
      let param = {
        action: "addbankcard",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          methods: this.bankname,
          cardno: this.banknum,
          name: this.bankname_user,
          bankcity: this.subbankname,
          image: this.bankimage,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.$store.commit("ADDCARD", false);
          localStorage.setItem("first", "");
          this.$MSG.API_popmsg(this.$t("text_bank_succ"), "", "success");
        } else {
          this.$MSG.API_popmsg(status, "", "error");
        }
      });
    },
    step_back() {
      this.flag_two = false;
    },
    vali_cardnum(value) {
      var pattern = new RegExp("[0-9]");
      var a_k_url = value.split("");
      var flag_p;
      a_k_url.forEach((element) => {
        if (pattern.test(element)) {
          if (flag_p != false) flag_p = true;
        } else flag_p = false;
      });
      return flag_p;
    },
    vali_name(value) {
      var pattern = new RegExp("[\u4e00-\u9fa5]");
      var a_k_url = value.split("");
      var flag_p;
      a_k_url.forEach((element) => {
        if (pattern.test(element)) {
          if (flag_p != false) flag_p = true;
        } else flag_p = false;
      });
      return flag_p;
    },
    bankList: function () {
      let param = {
        action: "banklist",
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.list = res.list;
        this.bankname = this.list[0];
      });
    },
  },
  created() {
    this.bankList();
  },
  watch: {},
};
</script>
