<template>
  <div class="winner-cotainer pt-0">
    <div class="winner-title mb-0">
      <img src="@/assets/img/new_img/dragon_tiger.png" class="w-100"/>
    </div>
    <div class="d-lg-flex justify-content-between">
      <div
        class="me-lg-2 text-center p-2 rounded-3"
        style="flex-grow: 1; background-color: #0153bd"
      >
        <span class="fw-bold text-white" style="font-size: 20px">{{
          $t("text_new_rank_3")
        }}</span>
        <div class="mt-3" style="border-radius: 16px" id="winner-div_dw">
          <div class="div-header header_dw">
            <span>{{ $t("home_text4") }}</span>
            <span>{{ $t("transaction_Amount") }}</span>
          </div>
          <div
            class="div-container"
            style="
              height: 45vh;
              overflow-y: hidden;
              border-bottom: 15px solid #0153bd;
              border-radius: 16px;
            "
          >
            <div
              id="winner-item-cotainer_dw"
              v-for="l in 2"
              :key="l"
              :style="{ transform: 'translateY(-' + scrollTop + 'px)' }"
            >
              <div class="content1 content1_dw" v-for="l in tableData" :key="l">
                <span>{{ l.userid }}</span>
                <span>
                  <img
                    src="@/assets/img/main/diamond.png"
                    style="width: 25px; height: 25px"
                    class="me-1"
                  />

                  <!-- {{ $t("cur") }} -->
                  {{ l.win }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="ms-lg-2 text-center p-2 rounded-3 mt-2"
        style="flex-grow: 1; background-color: #dc3545"
      >
        <span class="fw-bold text-white" style="font-size: 20px">{{
          $t("text_new_rank_4")
        }}</span>
        <div class="mt-3" style="border-radius: 16px">
          <div class="div-header header_dw">
            <span>{{ $t("home_text4") }}</span>
            <span>{{ $t("transaction_Amount") }}</span>
          </div>
          <div
            class="div-container"
            style="
              height: 45vh;
              overflow-y: hidden;
              border-bottom: 15px solid #dc3545;
              border-radius: 16px;
            "
          >
            <div
              v-for="l in 2"
              :key="l"
              :style="{ transform: 'translateY(-' + scrollTop + 'px)' }"
            >
              <div
                class="content1 content1_dw"
                v-for="l in tableData_w"
                :key="l"
              >
                <span>{{ l.userid }}</span>
                <span>
                  <img
                    src="@/assets/img/main/diamond.png"
                    style="width: 25px; height: 25px"
                    class="me-1"
                  />

                  <!-- {{ $t("cur") }} -->
                  {{ l.win }}</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {},
  data() {
    return {
      tableData: [
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
      ],
      tableData_w: [
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
        {
          userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
          win: Math.ceil(Math.random() * 99899 + 10),
        },
      ],
      timer: "",
      scrollTop: "",
      scrollHeight: "",
    };
  },
  methods: {
    scroll() {
      setInterval(() => {
        var new_arr = [];
        var insert_data = [];
        for (var i = 0; i < this.tableData.length; i++) {
          insert_data = [
            {
              userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
              win: Math.ceil(Math.random() * 99899 + 10),
            },
          ];
          new_arr.push(insert_data[0]);
        }
        this.tableData = new_arr;
      }, 1000);
    },
    scroll_w() {
      setInterval(() => {
        var new_arr = [];
        var insert_data = [];
        for (var i = 0; i < this.tableData_w.length; i++) {
          insert_data = [
            {
              userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
              win: Math.ceil(Math.random() * 99899 + 10),
            },
          ];
          new_arr.push(insert_data[0]);
        }
        this.tableData_w = new_arr;
      }, 1000);
    },

    new_scroll() {
      if (this.scrollTop < this.scrollHeight) {
        this.scrollTop = this.scrollTop + 1.5;
      } else {
        this.scrollTop = 0;
      }
    },
  },
  mounted() {
    var ele_id = document.getElementById("winner-div_dw");
    this.scrollTop = ele_id.scrollTop;

    // this.boxHeight = window.getComputedStyle(ele_id).getPropertyValue("height"); //取得div的高度
    // this.boxHeight = parseInt(this.boxHeight);

    this.scrollHeight = document.getElementById(
      "winner-item-cotainer_dw"
    ).scrollHeight;

    this.timer = setInterval(() => {
      this.new_scroll();
    }, 30);
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  created() {
    // this.scroll();
    // this.scroll_w();
  },
  components: {},
};
</script>
