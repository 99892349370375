<template>
  <!-- 侧边的nav -->
  <div>
    <div class="prewindow_div" @click="hide_nav()" :class="pre_top1_div"></div>
    <div
      class="collapse navbar-collapse"
      id="navbarNav"
      :class="flag_nav ? 'show' : ''"
    >
      <div class="icon-wrapper" @click="hide_nav()">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 13 13"
        >
          <path
            fill="#FFF"
            fill-rule="evenodd"
            d="M12.684 1.85c.211-.211.316-.467.316-.767s-.105-.555-.316-.767A1.045 1.045 0 0011.917 0c-.3 0-.556.105-.768.316L6.5 4.966 1.85.315A1.045 1.045 0 001.084 0c-.3 0-.555.105-.767.316A1.045 1.045 0 000 1.083c0 .3.105.556.316.768L4.966 6.5l-4.65 4.65a1.045 1.045 0 00-.316.767c0 .3.105.555.316.767.212.211.468.316.767.316.3 0 .556-.105.768-.316L6.5 8.034l4.65 4.65c.211.211.467.316.767.316s.555-.105.767-.316c.211-.212.316-.468.316-.767 0-.3-.105-.556-.316-.768L8.034 6.5l4.65-4.65z"
          ></path>
        </svg>
      </div>

      <div class="left-new-logo">
        <img :src="logo" alt="Logo" />
      </div>
      <p class="text-gold fw-bolder left-new-span mt-5 mb-4">{{ uid }}</p>

      <div class="d-flex flex-column w-100 navbar-div-nav" id="scro_left">
        <ul class="navbar-nav flex-column mb-5" v-if="Id != 'club'">
          <li @click="this.$router.push('/'), false_nav()">
            <img
              src="@/assets/img/new_left/side_ic1_home.png"
              :title="$t('text_left_home')"
            />
            <a class="nav-link fw-bold">{{ $t("text_left_home") }}</a>
          </li>
          <li @click="this.$router.push('/store'), false_nav()">
            <img
              src="@/assets/img/new_left/side_ic2_shop.png"
              :title="$t('text_main20')"
            />
            <a class="nav-link fw-bold">{{ $t("text_main20") }}</a>
          </li>
          <li @click="this.$router.push('/slot/hot'), false_nav()">
            <img
              src="@/assets/img/new_left/side_ic3_hot.png"
              :title="$t('text_main11_1')"
            />
            <a class="nav-link fw-bold">{{ $t("text_main11_1") }}</a>
          </li>
          <li @click="this.$router.push('/slot/slot'), false_nav()">
            <img
              src="@/assets/img/new_left/side_ic4_slot.png"
              :title="$t('home_text7_1')"
            />
            <a class="nav-link fw-bold">{{ $t("home_text7_1") }}</a>
          </li>
          <!-- <li @click="this.$router.push('/slot/casino'), false_nav()">
            <img
              src="@/assets/img/new_left/side_ic5_card.png"
              :title="$t('home_text6_1')"
            />
            <a class="nav-link fw-bold">{{ $t("home_text6_1") }}</a>
          </li> -->
          <!-- <li @click="this.$router.push('/slot/chess'), false_nav()">
            <img
              src="@/assets/img/new_left/side_ic5_card.png"
              :title="$t('text_chess_1')"
            />
            <a class="nav-link fw-bold">{{ $t("text_chess_1") }}</a>
          </li>
          <li @click="this.$router.push('/slot/fish'), false_nav()">
            <img
              src="@/assets/img/new_left/side_ic6_fish.png"
              :title="$t('text_main12_1')"
            />
            <a class="nav-link fw-bold">{{ $t("text_main12_1") }}</a>
          </li> -->
          <li @click="this.$router.push('/promo'), false_nav()">
            <img
              src="@/assets/img/new_left/side_ic7_promo.png"
              :title="$t('text_pro_act')"
            />
            <a class="nav-link fw-bold">{{ $t("text_pro_act") }}</a>
          </li>
          <li @click="this.$router.push('/service_center'), false_nav()">
            <img
              src="@/assets/img/new_left/side_ic8_serv.png"
              :title="$t('left_menu_text8')"
            />
            <a class="nav-link fw-bold">{{ $t("left_menu_text8") }}</a>
          </li>
          <li v-if="flag_code_uid" @click="logout" class="mb-4">
            <img src="@/assets/img/left/logout.png" :title="$t('logout')" />
            <a class="nav-link fw-bold">{{ $t("logout") }}</a>
          </li>
        </ul>
        <maintop1_club v-else />
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex";
// import Lang from "./Home/language.vue";
import maintop1_club from "./Main_top1_club.vue";
import {
  main_casino_on,
  main_sport_on,
  main_casino_off,
  main_sport_off,
} from "@/components/Img/img";

export default {
  name: "maintop1",
  data() {
    return {
      code: Cookies.get("code"),
      uid: localStorage.getItem("uid"),
      shareurl: "",
      sharecode: "",
      img1: require("@/assets/img/left/menu_ic_1_home.png"),
      img2: require("@/assets/img/left/menu_ic_2_member.png"),
      img3: require("@/assets/img/left/menu_ic_3_share.png"),
      img4: require("@/assets/img/left/menu_ic_4_recharge.png"),
      img5: require("@/assets/img/left/menu_ic_5_myaccount.png"),
      img6: require("@/assets/img/left/menu_ic_6_record.png"),
      img7: require("@/assets/img/left/menu_ic_7_myactivity.png"),
      img8: require("@/assets/img/left/menu_ic_8_messages.png"),
      img9: require("@/assets/img/left/menu_ic_9_vipbenefits.png"),
      img10: require("@/assets/img/left/menu_ic_billboard.png"),
      img11: require("@/assets/img/left/menu_ic_partner.png"),
      img12: require("@/assets/img/left/menu_ic_10_customerservice.png"),
      img13: require("@/assets/img/left/menu_ic_11_tg.png"),
      img14: require("@/assets/img/left/menu_ic_12_fb.png"),
      img15: require("@/assets/img/left/logout.png"),
      img_casino: JSON.parse(JSON.stringify(main_casino_on))[
        localStorage.getItem("locale")
      ],
      img_sport: JSON.parse(JSON.stringify(main_sport_on))[
        localStorage.getItem("locale")
      ],
      img_casino_off: JSON.parse(JSON.stringify(main_casino_off))[
        localStorage.getItem("locale")
      ],
      img_sport_off: JSON.parse(JSON.stringify(main_sport_off))[
        localStorage.getItem("locale")
      ],
    };
  },
  components: {
    // Lang,
    maintop1_club,
  },
  computed: {
    ...mapGetters(["logo"]),
    ...mapGetters([
      "flag_nav",
      "balance",
      "flag_wheel",
      "flag_sign",
      "flag_extra",
      "flag_inter",
    ]),
    Id: function () {
      if (this.$route.params.id != null) return this.$route.params.id;
      else return "";
    },
    pre_top1_div: function () {
      if (window.innerWidth <= 1199 && this.flag_nav) {
        return "d-block";
      } else return "d-none";
    },

    flag_code_uid() {
      return Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
        ? false
        : true;
    },
  },
  methods: {
    ...mapActions(["getLogo", "getService"]),
    ...mapActions(["getBalance", "logout_public"]),
    logout: function () {
      this.logout_public();
      this.$router.push("/");
      window.location.reload();
    },
    hide_nav: function () {
      this.$store.commit("flagnav", !this.flag_nav);
    },
    false_nav: function () {
      if (window.innerWidth <= 1199) this.$store.commit("flagnav", false);
      else this.$store.commit("flagnav", this.flag_nav);
    },
    share: function () {
      let param = {
        action: "share_info",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          domain: window.location.host,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res["status"];
        if (status == "success") {
          this.shareurl = res["url"];
          this.sharecode = res["ref"];
        }
      });
    },
    Copy: function (e) {
      var content = e == "code" ? this.sharecode : this.shareurl;
      this.$copyText(content).then(
        () => {
          this.$MSG.API_popmsg(
            this.$t("text_copied") + "<br/>" + content,
            "",
            "success"
          );
        },
        (e) => {
          this.$MSG.API_popmsg(
            this.$t("text_notcopy") + "： " + e.text,
            "",
            "error"
          );
        }
      );
    },
    go_home() {
      this.$router.push("/");
    },
    change_img(id) {
      return require("@/assets/img/left/hover/" + id + ".png");
    },
    change_img_nothover(id) {
      return require("@/assets/img/left/" + id + ".png");
    },
    newmain_view: function () {
      this.$router.push("/slot/hot");
    },
    click_sport: function () {
      this.$router.push("/main_sport");
    },
    click_pro: function () {
      this.$router.push("/promo");
    },
    open_wheel_fun: function () {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        this.$store.commit("flag_wheel", true);
      }
    },
    open_sign_fun: function () {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        this.$store.commit("flag_sign", true);
      }
    },
    open_extra_fun: function () {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        this.$store.commit("flag_extra", true);
      }
    },
    link: function (e) {
      var url = "";
      switch (e) {
        case "service":
          url = this.$store.state.service.qq.qq;
          break;
        default:
          url = this.$store.state.service.qq[e];
          break;
      }
      window.open(url);
    },
    jump_pop: function () {
      if (
        localStorage.getItem("utypeid") == null ||
        localStorage.getItem("utypeid") == "" ||
        localStorage.getItem("utypeid") == 10
      )
        this.$MSG.API_popmsg(this.$t("text_level_tips"), "", "warning");
      else {
        this.$router.push("/home_vip");
      }
    },
  },
  created() {
    this.getLogo();
    if (
      Cookies.get("code") != null &&
      Cookies.get("code") != "" &&
      localStorage.getItem("uid") != null &&
      localStorage.getItem("uid") != ""
    ) {
      this.getBalance();
      this.share();
    }
    if (window.innerWidth <= 1199) this.$store.commit("flagnav", false);
    this.getService();
  },
  watch: {},
};
</script>
