<template>
  <div
    class="mx-auto mb-5"
    style="max-width: 1200px; min-height: 550px; padding-top: 30px"
  >
    <div class="d-flex w-100">
      <div class="tab_container tab3-content">
        <h1 class="help_title">{{ $t("footer_r") }}</h1>
        <div v-html="$t('help_con5')"></div>
      </div>
    </div>
  </div>
</template>
