import { createStore } from "vuex";
import Cookies from "js-cookie";
import api from "../components/http/api";
import msg from "../components/http/msgbox";
import { dist2 } from "@/components/Game/GameList";
export default createStore({
  state: {
    isLoading: false,
    islang: false,
    isPreImg: false,
    isann: true,
    pop: {
      isLoading: false,
      data: {
        action: "",
        msg: "",
        class: "",
        type: "",
      },
    },
    parameter: {
      action: "",
      body: {},
    },
    service: {
      qq: "",
    },
    list: [],
    balance: 0,
    logo: "",
    flag_nav: true,
    watch_newWindowurl: "",
    islogin: false,
    isreg: false,
    all_game: {
      casino: [],
      slot: ["atg"],
    },
    flag_code:
      Cookies.get("code") == null ||
      Cookies.get("code") == "" ||
      localStorage.getItem("uid") == null ||
      localStorage.getItem("uid") == ""
        ? false
        : true,
    flag_app: false,
    flag_wheel: false,
    flag_sign: false,
    flag_extra: false,
    master_balance_hall: 0,
    member_total_coin: 0,
    flag_inter: false,
  },
  mutations: {
    Loading(state, payload) {
      state.isLoading = payload;
    },
    Lang(state, payload) {
      state.islang = payload;
    },
    PreImg(state, payload) {
      state.isPreImg = payload;
    },
    isann(state, payload) {
      state.isann = payload;
    },
    Pop(state, payload) {
      state.pop = payload;
    },
    Service(state, payload) {
      state.service.qq = payload;
    },
    GetIP() {
      api.getIP().then((res) => {
        localStorage.setItem("ip", res);
      });
    },
    GetList(state, payload) {
      state.list = payload;
    },
    Getbalance(state, payload) {
      state.balance = payload;
    },
    GetLogo(state, payload) {
      state.logo =
        payload.logo == "" ? require("@/assets/logo.png") : payload.logo;
    },
    flagnav(state, payload) {
      state.flag_nav = payload;
    },
    watch_newWindowurl(state, payload) {
      state.watch_newWindowurl = payload;
    },
    islogin(state, payload) {
      state.islogin = payload;
    },
    isreg(state, payload) {
      state.isreg = payload;
    },
    flag_code(state, payload) {
      state.flag_code = payload;
    },
    flag_app(state, payload) {
      state.flag_app = payload;
    },
    flag_wheel(state, payload) {
      state.flag_wheel = payload;
    },
    flag_sign(state, payload) {
      state.flag_sign = payload;
    },
    flag_extra(state, payload) {
      state.flag_extra = payload;
    },
    master_balance_hall(state, payload) {
      state.master_balance_hall = payload;
    },
    member_total_coin(state, payload) {
      state.member_total_coin = payload;
    },
    flag_inter(state, payload) {
      state.flag_inter = payload;
    },
  },
  actions: {
    setGetIP(context) {
      context.commit("GetIP");
    },
    setLoading(context, payload) {
      context.commit("Loading", payload);
    },
    setLang(context, payload) {
      context.commit("Lang", payload);
    },
    showPop(context, payload) {
      context.commit("Pop", payload);
    },
    getpost(context, parameter) {
      return new Promise((resolve) => {
        api.apiPost(parameter.action, parameter.body).then((res) => {
          if (msg.resPonseStatus(res)) {
            if (res.status == "fail") {
              if (parameter.action == "extra_bonus") resolve(res);
              msg.API_popmsg(res.msg, "", "error");
            } else if (res.status == "relogin") {
              msg.API_popmsg(res.msg, "relogin", "error");
            } else {
              resolve(res);
            }
          }
        });
      });
    },
    getService(context) {
      this.dispatch("getpost", { action: "service" }).then((res) => {
        context.commit("Service", res);
      });
    },
    // getList(context, parameter) {
    //   context.commit("GetList", []);
    //   this.dispatch("getpost", parameter).then((res) => {
    //     try {
    //       context.commit("GetList", JSON.parse(res)["data"]);
    //     } catch {
    //       context.commit("GetList", JSON.parse(res)["list"]);
    //     }
    //   });
    // },
    getGameList(context, parameter) {
      parameter.body.gametype = parameter.body.gametype.toLowerCase();
      parameter.body.type = parameter.body.type.toLowerCase();
      if (parameter.action != "") {
        if (
          localStorage.getItem(
            parameter.body.gametype + "_" + parameter.body.type + "_gamelist"
          ) == null ||
          parameter.body.gametype == "guess"
        ) {
          this.dispatch("getpost", parameter).then((res) => {
            var list = res["list"];
            list.forEach((v) => {
              if (
                (v["gamekind"] == "casino" &&
                  parameter.body.gametype == "guess") ||
                v["gamekind"] == "sport" ||
                v["gametype"] == "IGS" ||
                v["gametype"] == "allcasino" ||
                (v["gamekind"] == "lottery" &&
                  parameter.body.gametype == "guess")
              ) {
                v["iconName"] = dist2.filter((i) => {
                  return (
                    i["gameType"] == v["gametype"] &&
                    v["gamekind"] == i["gameKind"]
                  );
                })[0]["iconName"];
              } else {
                v["iconName"] = "http://gameweb.metaversement.io/" + v["image"];
              }
              v["gameId"] = v["gameid"];
              v["gameType"] = v["gametype"];
              v["gameKind"] = v["gamekind"];
              v["gameName"] = v["gamename"];
            });
            localStorage.setItem(
              parameter.body.gametype + "_" + parameter.body.type + "_gamelist",
              JSON.stringify(list)
            );
            context.commit("GetList", list);
          });
        } else {
          list = JSON.parse(
            localStorage.getItem(
              parameter.body.gametype + "_" + parameter.body.type + "_gamelist"
            )
          );
          list.forEach((v) => {
            if (
              v["gamekind"] == "allcasino" ||
              v["gamekind"] == "sport" ||
              v["gametype"] == "IGS"
            ) {
              v["iconName"] = dist2.filter((i) => {
                return (
                  i["gameType"] == v["gametype"] &&
                  v["gamekind"] == i["gameKind"]
                );
              })[0]["iconName"];
            } else {
              v["iconName"] = "http://gameweb.metaversement.io/" + v["image"];
            }
            v["gameId"] = v["gameid"];
            v["gameType"] = v["gametype"];
            v["gameKind"] = v["gamekind"];
            v["gameName"] = v["gamename"];
          });
          context.commit("GetList", list);
        }
      } else {
        if (
          localStorage.getItem(
            parameter.body.gametype + "_" + parameter.body.type + "_gamelist"
          ) == null
        ) {
          var list = [];
          list = dist2.filter((v) => {
            if (parameter.body.gametype === "all") {
              return v;
            } else {
              return (
                String(v["Gamestype"]).toLowerCase() == parameter.body.gametype
              );
            }
          });
          localStorage.setItem(
            parameter.body.gametype + "_" + parameter.body.type + "_gamelist",
            JSON.stringify(list)
          );
          context.commit("GetList", list);
        } else {
          list = JSON.parse(
            localStorage.getItem(
              parameter.body.gametype + "_" + parameter.body.type + "_gamelist"
            )
          );
          context.commit("GetList", list);
        }
      }
      // context.commit("GetList", list);
    },
    getLogo(context) {
      this.dispatch("getpost", {
        action: "Set_Logo",
        body: {
          regdomain: document.location.origin,
        },
      }).then((res) => {
        context.commit("GetLogo", res);
        if (res.status == "no match") {
          // document.title = this.$t("text_web_title");
          // let s = document.createElement('meta');
          // s.setAttribute('name', 'description');
          // s.setAttribute('content', this.$t("text_web_description"));
          // document.head.appendChild(s);
          // s = document.createElement('meta');
          // s.setAttribute('name', 'keywords');
          // s.setAttribute('content', this.$t("text_web_keyword"));
          // document.head.appendChild(s);
          // const link = document.querySelector("[rel='icon']");
          // link.setAttribute("href", 'favicon.ico');
        } else {
          document.title = res.title;
          document
            .querySelector('meta[name="description"]')
            .setAttribute(
              "content",
              res.cname + this.$t("text_web_description")
            );
          document
            .querySelector('meta[name="keywords"]')
            .setAttribute("content", res.keyword);
          const link = document.querySelector("[rel='icon']");
          link.setAttribute("href", res.logo);
        }
      });
    },
    getBalance(context) {
      this.dispatch("getpost", {
        action: "balance",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      }).then((res) => {
        this.dispatch("filter_number", Math.floor(res["balance"])).then(
          (res) => {
            context.commit("Getbalance", res);
          }
        );
      });
    },
    logout_public(context) {
      Cookies.remove("code");
      localStorage.removeItem("uid");
      localStorage.removeItem("utype");
      localStorage.removeItem("utypeid");
      localStorage.removeItem("clubid");
      localStorage.removeItem("big_logo");
      context.commit("flag_code", false);
    },
    filter_number(context, value) {
      if (!value) return 0;
      const valueArray = value.toString().split(".");
      let regex = /(\d)(?=(?:\d{3})+$)/g;
      if (valueArray.length === 2) {
        const intPart = valueArray[0].toString().replace(regex, "$1,");
        let floatPart = "";
        floatPart = valueArray[1].toString();
        return intPart + "." + floatPart;
      } else {
        const intPart = value.toString().replace(regex, "$1,");
        return intPart;
      }
    },
  },
  getters: {
    pop: (state) => {
      return state.pop;
    },
    islang: (state) => {
      return state.islang;
    },
    isLoading: (state) => {
      return state.isLoading;
    },
    isPreImg: (state) => {
      return state.isPreImg;
    },
    isann: (state) => {
      return state.isann;
    },
    service: (state) => {
      return state.service;
    },
    list: (state) => {
      return state.list;
    },
    logo: (state) => {
      return state.logo;
    },
    flag_nav: (state) => {
      return state.flag_nav;
    },
    watch_newWindowurl: (state) => {
      return state.watch_newWindowurl;
    },
    islogin: (state) => {
      return state.islogin;
    },
    isreg: (state) => {
      return state.isreg;
    },
    all_game: (state) => {
      return state.all_game;
    },
    balance: (state) => {
      return state.balance;
    },
    flag_code: (state) => {
      return state.flag_code;
    },
    flag_app: (state) => {
      return state.flag_app;
    },
    flag_wheel: (state) => {
      return state.flag_wheel;
    },
    flag_sign: (state) => {
      return state.flag_sign;
    },
    flag_extra: (state) => {
      return state.flag_extra;
    },
    master_balance_hall: (state) => {
      return state.master_balance_hall;
    },
    member_total_coin: (state) => {
      return state.member_total_coin;
    },
    flag_inter: (state) => {
      return state.flag_inter;
    },
  },
});
