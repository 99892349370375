<template>
  <div id="salary">
    <div class="mt-xl-3 mt-1 border_eee box_shadow p-2">
      <div class="d-flex w-100">
        <div class="w-100">
          <div class="C000062_tabPaneContent__CjMnc">
            <div class="Salary_root__B+AxD">
              <div
                class="Salary_datePicker__NmhAM justify-content-end border_eee box_shadow p-2 mb-4 border-lg-0"
              >
                <div
                  class="ant-picker ant-picker-range ant-picker-large d-inline-block w-xl-75 text-xl-start"
                >
                  <div class="ant-picker-input">
                    <div class="dateControlLabel text_gray w-50 fs_16">
                      {{ $t("start_date") }}
                    </div>
                    <input
                      type="date"
                      class="w-auto"
                      id="begindate"
                      @change="begindate_change"
                    />
                  </div>
                  <div class="ant-picker-input">
                    <div class="dateControlLabel text_gray w-50 fs_16">
                      {{ $t("end_date") }}
                    </div>
                    <input
                      type="date"
                      class="w-auto"
                      id="enddate"
                      @change="enddate_change"
                    />
                  </div>
                </div>
              </div>
              <div class="Salary_summary__SjfN4">
                <div class="GbTable_root__aRhLC rounded-0">
                  <table
                    style="table-layout: auto"
                    class="w-100 GbTable_table__moqvH"
                  >
                    <thead class="ant-table-thead">
                      <tr>
                        <th>
                          <img
                            src="@/assets/img/invite/badge.png"
                            style="height: 24px"
                          />
                        </th>
                        <th>{{ $t("text_total") }}</th>
                      </tr>
                    </thead>
                    <tbody class="ant-table-tbody">
                      <tr
                        data-row-key="Grand Total"
                        class="ant-table-row ant-table-row-level-0"
                      >
                        <td>{{ $t("grand_total") }}</td>
                        <td>
                          <span>
                            <img
                              src="@/assets/img/main/diamond.png"
                              style="width: 25px; height: 25px"
                              class="me-1"
                            />
                            {{ total }}
                          </span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="Salary_summaryM__cL5yY border_eee box_shadow">
                <div class="Salary_header__R9+bZ">
                  <span>
                    <img
                      src="@/assets/img/invite/badge.png"
                      style="height: 24px"
                    />
                  </span>
                  <span> {{ $t("text_total") }} </span>
                </div>
                <div class="Salary_content__Sd6io">
                  <span> {{ $t("grand_total") }} </span>
                  <span>
                    <img
                      src="@/assets/img/main/diamond.png"
                      style="width: 25px; height: 25px"
                      class="me-1"
                    />
                    {{ total }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import dateTime from "@/components/utils/datatime";

export default {
  name: "salary",
  computed: {},
  data() {
    return {
      code: Cookies.get("code"),
      begin: dateTime.getoneday(0),
      end: dateTime.getoneday(0),
      total: 0,
    };
  },
  methods: {
    salary: function () {
      let param = {
        action: "salary",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          begindate: this.begin,
          enddate: this.end,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res["status"];
        if (status == "success") {
          var data = res["data"][0];
          this.total = data.total;
        }
      });
    },
    begindate_change: function () {
      this.begin = document.getElementById("begindate").value;
      if (
        Cookies.get("code") != null &&
        Cookies.get("code") != "" &&
        localStorage.getItem("uid") != null &&
        localStorage.getItem("uid") != ""
      ) {
        this.salary();
      }
    },
    enddate_change: function () {
      this.end = document.getElementById("enddate").value;
      if (
        Cookies.get("code") != null &&
        Cookies.get("code") != "" &&
        localStorage.getItem("uid") != null &&
        localStorage.getItem("uid") != ""
      ) {
        this.salary();
      }
    },
  },
  created() {
    if (
      Cookies.get("code") != null &&
      Cookies.get("code") != "" &&
      localStorage.getItem("uid") != null &&
      localStorage.getItem("uid") != ""
    ) {
      this.salary();
    }
  },
};
</script>
