<template>
  <div id="mobbottom" class="d-xl-none d-flex">
    <div class="mob_footer">
      <div class="footer_card" @click="this.$router.push('/ME/wallet/deposit')">
        <div class="footer_icon d-flex flex-column">
          <img src="@/assets/img/main/main_bottom_ic1_deposit.png" alt="" />
          <span>{{ $t("deposit") }}</span>
        </div>
      </div>
      <div class="footer_card" @click="this.$router.push('/store')">
        <div class="footer_icon d-flex flex-column">
          <img src="@/assets/img/main/main_bottom_ic2_shop.png" alt="" />
          <span>{{ $t("withdrawl") }}</span>
        </div>
      </div>
      <div class="footer_card" @click="this.$router.push('/')">
        <div class="footer_icon d-flex flex-column">
          <img src="@/assets/img/main/ic3_home.png" alt="" />
          <span>{{ $t("go_home") }}</span>
        </div>
      </div>
      <!-- <div class="footer_card" @click="this.$router.push('/promo')">
        <div class="footer_icon d-flex flex-column">
          <img src="@/assets/img/main/ic4_event.png" alt="" />
          <span>{{ $t("faq_5") }}</span>
        </div>
      </div> -->
      <div class="footer_card" @click="this.$router.push('/VIP')">
        <div class="footer_icon d-flex flex-column">
          <img src="@/assets/img/main/ic5_mypage.png" alt="" />
          <span>{{ $t("text_top_vip") }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["flag_nav"]),
  },
  methods: {
    show_nav: function () {
      this.$store.commit("flagnav", !this.flag_nav);
    },
  },
};
</script>
