<template>
  <div>
    <ul class="navbar-nav flex-column mb-5">
      <!-- <li
        @click="this.$router.push('/club'), false_nav()"
        class="justify-content-center only_img"
      >
        <img
          src="@/assets/img/d_club/bt_game.png"
          :title="$t('text_club5')"
          style="width: 75%"
        />
      </li> -->
      <li
        class="justify-content-center flex-column bg-ccc"
        @click="this.$router.push('/club'), false_nav()"
      >
        <a class="nav-link fw-bold">{{ $t("text_vip_home") }}</a>
      </li>
      <li class="justify-content-center flex-column mt-3 bg-ccc">
        <a class="nav-link fw-bold">{{ $t("text_vip_2") }}</a>
        <span style="color: #faa41e !important">{{ master_balance_hall }}</span>
      </li>
      <li class="justify-content-center flex-column bg-ccc">
        <a class="nav-link fw-bold">{{ $t("text_vip_3") }}</a>
        <span style="color: #faa41e !important">{{ member_total_coin }}</span>
      </li>
      <li
        class="justify-content-center flex-column bg-ccc"
        @click="this.$router.push('/club/viplogo'), false_nav()"
      >
        <a class="nav-link fw-bold">{{ $t("set_vip_logo") }}</a>
      </li>
      <li
        class="justify-content-center flex-column bg-ccc"
        @click="this.$router.push('/club/company_report'), false_nav()"
      >
        <a class="nav-link fw-bold">{{ $t("company_report_text1") }}</a>
      </li>
      <li
        @click="this.$router.push('/club/setgame'), false_nav()"
        class="justify-content-center mt-3"
      >
        <img
          src="@/assets/img/d_club/ic8_game-setting.png"
          :title="$t('set_game')"
        />
        <a class="nav-link fw-bold">{{ $t("set_game") }}</a>
      </li>
      <li
        @click="this.$router.push('/club/member'), false_nav()"
        class="justify-content-center"
      >
        <img
          src="@/assets/img/d_club/ic1_member.png"
          :title="$t('text_club5')"
        />
        <a class="nav-link fw-bold">{{ $t("text_club5") }}</a>
      </li>
      <li
        @click="this.$router.push('/club/counter'), false_nav()"
        class="justify-content-center"
      >
        <img
          src="@/assets/img/d_club/ic2_counter.png"
          :title="$t('text_club6')"
        />
        <a class="nav-link fw-bold">{{ $t("text_club6") }}</a>
      </li>
      <li
        @click="this.$router.push('/club/gamedata'), false_nav()"
        class="justify-content-center"
      >
        <img src="@/assets/img/d_club/ic3_data.png" :title="$t('text_club7')" />
        <a class="nav-link fw-bold">{{ $t("text_club7") }}</a>
      </li>
      <li
        @click="this.$router.push('/club/financial'), false_nav()"
        class="justify-content-center"
      >
        <img
          src="@/assets/img/d_club/ic4_finance.png"
          :title="$t('text_club14')"
        />
        <a class="nav-link fw-bold">{{ $t("text_club14") }}</a>
      </li>
      <li
        @click="this.$router.push('/club/agent'), false_nav()"
        class="justify-content-center"
      >
        <img
          src="@/assets/img/d_club/ic5_agent.png"
          :title="$t('text_club15')"
        />
        <a class="nav-link fw-bold">{{ $t("text_club15") }}</a>
      </li>
      <li
        @click="this.$router.push('/club/loan'), false_nav()"
        class="justify-content-center"
      >
        <img
          src="@/assets/img/d_club/ic7_manage2.png"
          :title="$t('club_text41')"
        />
        <a class="nav-link fw-bold">{{ $t("club_text41") }}</a>
      </li>
      <li
        @click="this.$router.push('/club/countertop'), false_nav()"
        class="justify-content-center"
      >
        <img
          src="@/assets/img/d_club/ic8_manage1.png"
          :title="$t('bottom_text1')"
        />
        <a class="nav-link fw-bold">{{ $t("bottom_text1") }}</a>
      </li>
      <li
        @click="this.$router.push('/club/casino_comm'), false_nav()"
        class="justify-content-center"
      >
        <img
          src="@/assets/img/d_club/ic9_baccarat-com.png"
          :title="$t('casino_commission_text_1')"
        />
        <a class="nav-link fw-bold">{{ $t("casino_commission_text_1") }}</a>
      </li>
      <!-- <li @click="click_coming(), false_nav()" class="justify-content-center">
      <a class="nav-link fw-bold">{{ $t("text_club15") }}</a>
    </li> -->
      <li v-if="flag_code_uid" @click="logout" class="justify-content-center">
        <img src="@/assets/img/d_club/ic6_logout.png" :title="$t('logout')" />
        <a class="nav-link fw-bold">{{ $t("logout") }}</a>
      </li>
    </ul>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "maintop1",
  data() {
    return {
      code: Cookies.get("code"),
      uid: localStorage.getItem("uid"),
    };
  },
  components: {},
  computed: {
    ...mapGetters(["logo"]),
    ...mapGetters([
      "flag_nav",
      "balance",
      "master_balance_hall",
      "member_total_coin",
    ]),

    flag_code_uid() {
      return Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
        ? false
        : true;
    },
  },
  methods: {
    ...mapActions(["getLogo"]),
    ...mapActions(["getBalance", "logout_public"]),
    logout: function () {
      this.logout_public();
      this.$router.push("/");
      window.location.reload();
    },
    false_nav: function () {
      if (window.innerWidth <= 1199) this.$store.commit("flagnav", false);
      else this.$store.commit("flagnav", this.flag_nav);
    },
    // click_coming: function () {
    //   this.$MSG.API_popmsg(this.$t("text_coming"), "", "warning");
    // },
    get_info() {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "myclub_vip",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.status == "success") {
            if (res.master_club_list.length > 0) {
              this.$store.commit(
                "master_balance_hall",
                res.master_club_list[0].master_balance_hall
              );
              this.$store.commit(
                "member_total_coin",
                res.master_club_list[0].balance_hall
              );
            }
          } else this.$MSG.API_popmsg(res.msg, "", "warning");
        });
      }
    },
  },
  created() {
    this.getLogo();
    this.get_info();
    if (window.innerWidth <= 1199) this.$store.commit("flagnav", false);
  },
  watch: {},
};
</script>
