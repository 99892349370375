<template>
  <div
    class="wc-PageView_ContentContainer wc-LegacyPage_ContentContainerBankBalance wc-ModulePageChangePageData_ContentContainer"
  >
    <!-- <div class="change_lo_re fw-bolder mt-3">
      <span
        style="padding: 5px 30px; border: 1px solid"
        @click="id = 'money'"
        :class="id == 'money' ? 'thishover' : ''"
        >{{ $t("text_coin") }}
      </span>
      <span
        style="padding: 5px 30px; border: 1px solid"
        @click="id = 'dia'"
        :class="id == 'dia' ? 'thishover' : ''"
        >{{ $t("text_dia") }}
      </span>
      <span
        style="padding: 5px 30px; border: 1px solid"
        @click="id = 'udia'"
        :class="id == 'udia' ? 'thishover' : ''"
        >{{ $t("text_usdt_b_dia") }}
      </span>
    </div> -->

    <div
      v-if="id == 'money'"
      class="mt-2 py-1 px-3 d-flex flex-wrap"
      style="overflow-y: auto"
    >
      <div
        class="position-relative col-6 p-2 mb-3"
        v-for="(l, k) in coin_amount"
        :key="l"
      >
        <div
          style="border: 1px solid"
          class="py-2 px-3 py-md-4 px-md-5 d_div_amtdia"
        >
          <img
            :src="require('@/assets/img/d_coin/coin-' + (k + 1) + '.png')"
            class="d_img_amtdia"
          />
          <p class="text-center my-3" style="color: #c95712">
            {{ change_num(l * 10000) }}
          </p>
          <button
            class="btn btn-blue-buy position-absolute bottom-0 w-75"
            style="left: 12%; border-radius: 50px"
            @click="show_pay_page(l * 10000, l, 'coin')"
          >
            <span class="align-items-center d-flex justify-content-center">
              <img
                src="@/assets/img/main/diamond.png"
                style="width: 25px; height: 25px"
                class="me-1"
              />
              {{ l }}
            </span>
          </button>
        </div>
      </div>
    </div>

    <div v-else-if="id == 'dia'" class="mt-2">
      <div
        class="d-flex flex-column justify-content-center align-items-center"
        v-if="flag_coinbuydia"
      >
        <p class="text-center mb-1">{{ $t("text_d_addbank19") }}</p>
        <button
          class="btn btn-blue-buy"
          @click="show_addbank_page()"
          style="padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x)"
        >
          {{ $t("text_real_bank") }}
        </button>
      </div>
      <div class="mt-2 py-1 px-3 d-flex flex-wrap" style="overflow-y: auto">
        <div
          class="position-relative col-6 p-2 mb-3"
          v-for="(l, k) in dia_amount"
          :key="l"
        >
          <div
            style="border: 1px solid"
            class="py-2 px-3 py-md-4 px-md-5 d_div_amtdia"
          >
            <img
              :src="require('@/assets/img/d_dia/diamond-' + (k + 1) + '.png')"
              class="d_img_amtdia"
            />
            <p class="text-center my-3" style="color: #c95712">
              {{ filter_number(l) }}
              <span style="text-decoration: line-through; color: #0dcaf0">{{
                filter_number(l / 2)
              }}</span>
            </p>
            <button
              class="btn btn-blue-buy position-absolute bottom-0 w-75"
              style="left: 12%; border-radius: 50px; opacity: 1"
              @click="show_pay_page(l, dia_amount1[k], 'dia')"
              :style="
                flag_coinbuydia ? 'cursor: no-drop;background-color: #CCC;' : ''
              "
              :disabled="flag_coinbuydia"
            >
              <span class="align-items-center">
                $
                {{ dia_amount1[k] }}
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="mt-2 py-1 px-3 d-flex flex-wrap"
      style="overflow-y: auto"
    >
      <div
        class="position-relative col-6 p-2 mb-3"
        v-for="(l, k) in dia_amount"
        :key="l"
      >
        <div
          style="border: 1px solid"
          class="py-2 px-3 py-md-4 px-md-5 d_div_amtdia"
        >
          <img
            :src="require('@/assets/img/d_dia/diamond-' + (k + 1) + '.png')"
            class="d_img_amtdia"
          />
          <p class="text-center my-3" style="color: #c95712">
            {{ filter_number(l) }}
            <span style="text-decoration: line-through; color: #0dcaf0">{{
              filter_number(l / 2)
            }}</span>
          </p>
          <button
            class="btn btn-blue-buy position-absolute bottom-0 w-75 btn-green-usbuy"
            style="left: 12%; border-radius: 50px"
            @click="show_pay_page(l, d_dia_amount1[k], 'd_dia')"
          >
            <span class="align-items-center d-flex justify-content-center">
              <img
                src="@/assets/img/main/new_usdt.png"
                style="width: 25px; height: 25px"
                class="me-1"
              />
              {{ d_dia_amount1[k] }}
              USDT
            </span>
          </button>
        </div>
      </div>
    </div>

    <deposit_temp
      :choose_dia_amount="choose_dia_amount"
      :choose_dia_amt="choose_dia_amt"
      :action="action"
      :mpkidx="mpkidx"
      :capital="capital"
      @fun_flag_page="fun_flag_page"
      @fun_flag_page_1="fun_flag_page_1"
      v-if="flag_deposit_page"
    />

    <addbank_temp @fun_flag_page="fun_flag_page" v-if="flag_addbank_page" />

    <newWindow
      :URL="newWindowurl"
      :PAGE="'Deposit'"
      @iframe_close="iframeclosed"
      v-show="this.newWindowurl != ''"
    ></newWindow>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import deposit_temp from "./Deposit_temp.vue";
import addbank_temp from "./add_deposit_bank.vue";

export default {
  name: "deposit",
  setup() {},
  data() {
    return {
      id: "udia",
      coin_amount: ["10", "50", "300", "800", "2000", "3000"],
      dia_amount: ["130", "260", "3250", "6500", "13000", "65000"],
      dia_amount1: ["188", "415", "639", "1503", "3200", "16000"],
      d_dia_amount1: ["10", "20", "25", "50", "100", "500"],
      flag_deposit_page: false,
      flag_addbank_page: false,
      choose_dia_amount: 0,
      choose_dia_amt: 0,
      action: "",
      code: Cookies.get("code"),
      flag_coinbuydia: false,
      newWindowurl: "",
      capital: "",
      cname: "",
      cardno: "",
      list: [],
      mpkidx: "",
      payment: 0,
    };
  },
  computed: {
    ...mapGetters(["isaddcard"]),
  },
  components: { deposit_temp, addbank_temp },
  watch: {},
  created() {
    this.flag_coinbuydia = this.show_addbank_flag();
    this.Choose();
  },
  mounted() {},
  methods: {
    change_num: function (num) {
      if (parseFloat(num) / 1000000000 > 1)
        return (parseFloat(num) / 1000000000).toFixed(0) + "B";
      else if (parseFloat(num) / 1000000 > 1)
        return (parseFloat(num) / 1000000).toFixed(0) + "M";
      else if (parseFloat(num) / 1000 > 1)
        return (parseFloat(num) / 1000).toFixed(0) + "K";
      else return parseFloat(num).toFixed(0);
    },
    filter_number: function (value) {
      if (!value) return "";
      const valueArray = value.toString().split(".");
      let regex = /(\d)(?=(?:\d{3})+$)/g;
      if (valueArray.length === 2) {
        const intPart = valueArray[0].toString().replace(regex, "$1,");
        let floatPart = "";
        floatPart = valueArray[1].toString();
        return intPart + "." + floatPart;
      } else {
        const intPart = value.toString().replace(regex, "$1,");
        return intPart;
      }
    },
    show_pay_page: function (l, k, action) {
      this.choose_dia_amount = l;
      this.choose_dia_amt = k;
      this.flag_deposit_page = true;
      this.action = action;
    },
    fun_flag_page: function (e) {
      this.flag_deposit_page = e;
      this.flag_addbank_page = e;
    },
    show_addbank_page: function () {
      if (this.id == "dia") {
        if (this.show_addbank_flag()) {
          this.$store.commit("ADDCARD", true);
          this.flag_addbank_page = true;
        } else {
          this.flag_addbank_page = false;
        }
      }
    },
    show_addbank_flag: function () {
      if (this.id == "dia") {
        if (
          (localStorage.getItem("first") == "first" || this.isaddcard) &&
          this.code != null &&
          this.code != ""
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    fun_flag_page_1: function (e, amt) {
      if (e == "dia") {
        this.payment = parseInt(amt);
        this.deposit_tmp();
      }
    },
    deposit_tmp: function () {
      let max, min, money;
      max = parseInt(this.payment);
      min = parseInt(this.payment);
      money = parseInt(this.payment);
      if (money >= min && money <= max) {
        let param = {
          action: "deposit_tmp",
          body: {
            uid: localStorage.getItem("uid"),
            session_code: Cookies.get("code"),
            amount: this.payment,
            mpkidx: this.mpkidx,
            capital: this.capital,
            cname: this.cname,
            cardno: this.cardno,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.status == "success") {
            this.newWindowurl = res.msg;
          } else {
            this.$MSG.API_popmsg(res.msg, "", "warning");
          }
        });
      } else {
        this.$MSG.API_popmsg(
          this.$t("deposit_min_tips") +
            "  " +
            this.Min +
            "," +
            this.$t("deposit_max_tips") +
            "  " +
            this.Max +
            ".",
          "",
          "error"
        );
      }
    },
    Choose: function () {
      let param = {
        action: "choose",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var str = "";
        try {
          this.list = res["data"].filter((v) => {
            if (!str.includes(v.cname)) {
              str = v.cname + ",";
              return v;
            }
          });
          this.onChange(0);
        } catch {
          console.log();
        }
      });
    },
    onChange: function (e) {
      var c = this.list[e];
      this.mpkidx = c.mpkidx;
      this.capital = c.limit;
      this.Min = parseInt(this.capital.split(",")[0]);
      this.Max = parseInt(this.capital.split(",")[1]);
    },
    iframeclosed(val) {
      this.newWindowurl = val;
    },
  },
};
</script>
