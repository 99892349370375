<template>
  <div class="casino-search-results">
    <div
      v-if="flag_show"
      class="casino-search-results-games games-container-holder"
    >
      <div
        class="casino-search-game"
        v-for="l in filterSearch"
        :key="l"
        @click="game(l.device, l.mode, l.gameId, l.gameType, l.gameKind)"
      >
        <div>
          <img
            v-lazy="l.iconName"
            class="casino-search-game__img"
            :alt="l.gameName"
            :title="l.gameName"
          />
          <div class="casino-search-game__info">
            <div class="search-game-info-title">{{ l.gamename }}</div>
            <!-- <div class="search-game-info-provider">
              {{ l.gametype.toUpperCase() }}
            </div> -->
          </div>
        </div>
        <!-- <button class="transparent-button transparent-button-active">
          {{ $t("home_text1") }}
        </button> -->
      </div>
    </div>
    <span v-else class="text-center m-auto">{{ $t("text_main14") }}</span>
  </div>
</template>
<script>
export default {
  props: { filterSearch: Array },
  components: {},
  data() {
    return {
      flag_show: true,
    };
  },
  methods: {
    game: function (device, mode, id, type, kind) {
      this.$parent.game(device, mode, id, type, kind);
    },
  },
  watch: {
    filterSearch() {
      if (this.filterSearch.length <= 0) this.flag_show = false;
      else this.flag_show = true;
    },
  },
  computed: {},
  mounted() {},
  created() {
    if (this.filterSearch.length <= 0) this.flag_show = false;
    else this.flag_show = true;
  },
};
</script>
