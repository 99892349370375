<template>
  <div
    style="
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 123456;
    "
  >
    <div
      :title="$t('text_home')"
      style="
        position: absolute;
        bottom: 0px;
        width: 100%;
        height: 5%;
        background-color: #000;
        cursor: pointer;
        text-align: center;
      "
      @click="iframe_closed"
      v-show="PAGE != 'Deposit'"
    >
      <img src="@/assets/img/left/menu_ic_1_home.png" style="height: 100%" />
    </div>
    <div
      :title="$t('history_back')"
      style="
        position: absolute;
        top: 0px;
        width: 100%;
        height: 5%;
        background-color: #ccc;
        cursor: pointer;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
      "
      @click="iframe_closed"
      v-show="PAGE == 'Deposit'"
    >
      <img src="@/assets/img/new_img/return.png" style="height: 60%" />
      <span>{{ $t("history_back") }}</span>
    </div>
    <iframe
      id="newWindow"
      :src="resetUrl(this.URL)"
      width="100%"
      :height="PAGE != 'Deposit' ? '95%' : '100%'"
      :style="PAGE != 'Deposit' ? '' : 'background:none;background-color:#FFF'"
    >
    </iframe>
  </div>
</template>
<script>
export default {
  name: "newWindow",
  props: {
    URL: String,
    PAGE: String,
  },
  data() {
    return {};
  },
  components: {},
  computed: {},
  methods: {
    resetUrl: function (e) {
      if (e != null && e != "") {
        if (String(e).indexOf("<form") != -1) {
          return process.env.VUE_APP_BASEURL + "/form_data.aspx?src=" + e;
        } else if (String(e).indexOf("http") != -1) {
          if (String(e).indexOf("images/system_error/maintence.jpg") != -1) {
            this.$emit("iframe_close", "");
            this.$MSG.API_popmsg(
              this.$t("text_maintence"),
              "gameerror",
              "error"
            );
          } else return e;
        } else {
          this.$emit("iframe_close", "");
          this.$MSG.API_popmsg(e, "gameerror", "error");
        }
      }
    },
    iframe_closed() {
      this.$emit("iframe_close", "");
    },
  },
  created() {},
  watch: {},
};
</script>
<style scoped>
#newWindow {
  background: url("~@/assets/img/main/35771931234507.564a1d2403b3a.gif") center
    no-repeat;
  background-color: rgba(255, 255, 255, 0.5);
}
</style>
