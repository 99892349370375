<template>
  <div>
    <div class="download-bar" v-if="flag_app">
      <div class="download-bar-icon position-absolute">
        <img src="favicon.ico" />
      </div>
      <div class="download-bar-btn position-absolute text-center">
        {{ $t("text_app_download") }}
      </div>
      <div
        class="download-bar-close position-absolute"
        @click="this.$store.commit('flag_app', !this.flag_app)"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 13 13"
        >
          <path
            fill="#FFF"
            fill-rule="evenodd"
            d="M12.684 1.85c.211-.211.316-.467.316-.767s-.105-.555-.316-.767A1.045 1.045 0 0011.917 0c-.3 0-.556.105-.768.316L6.5 4.966 1.85.315A1.045 1.045 0 001.084 0c-.3 0-.555.105-.767.316A1.045 1.045 0 000 1.083c0 .3.105.556.316.768L4.966 6.5l-4.65 4.65a1.045 1.045 0 00-.316.767c0 .3.105.555.316.767.212.211.468.316.767.316.3 0 .556-.105.768-.316L6.5 8.034l4.65 4.65c.211.211.467.316.767.316s.555-.105.767-.316c.211-.212.316-.468.316-.767 0-.3-.105-.556-.316-.768L8.034 6.5l4.65-4.65z"
          ></path>
        </svg>
      </div>
      <div class="download-bar-full-name d-flex align-items-center text-white">
        <span>賺很大</span>
      </div>
      <div class="download-star">
        <span class="star-icon" v-for="l in 5" :key="l"></span>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "top_app",
  computed: {
    ...mapGetters(["flag_app"]),
  },
  data() {
    return {};
  },
  methods: {},
  watch: {},
  created() {},
  components: {},
};
</script>
