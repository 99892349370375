<template>
  <div class="wc-PageView wc_myactivities">
    <div
      class="wc-PageView_ContentContainer wc-ModulePageChangePageData_ContentContainer"
    >
      <div>
        <div class="nh-NavigationHeaderModule nh-NavigationHeaderModule-wide">
          <div class="nh-NavigationHeaderModule_Title">
            {{ $t("invest_text7") }}
          </div>
        </div>
      </div>
      <div>
        <div class="container">
          <div class="panel-container">
            <section class="panel">
              <header class="panel-header panel-header__fullheight mb-0">
                <div class="panel-summary">
                  <div class="amount" style="color: #00af4c">
                    <img
                      src="@/assets/img/main/diamond.png"
                      style="width: 25px; height: 25px"
                      class="me-1"
                    />
                    {{ sum_getback }}
                  </div>

                  <!-- <div class="time-period">{{ begin }}-{{ end }}</div> -->
                </div>

                <div class="panel-title header-tool-tip-container">
                  <h1 class="m-0">{{ $t("invest_text8") }}</h1>
                </div>

                <!-- <h2 class="panel-subtitle">
                  {{ $t("invest_text9") }}
                </h2> -->
              </header>
            </section>

            <section class="panel">
              <header class="panel-header panel-header__fullheight">
                <div class="panel-summary">
                  <div class="amount">
                    <img
                      src="@/assets/img/main/diamond.png"
                      style="width: 25px; height: 25px"
                      class="me-1"
                    />
                    {{ sum_cost_unit }}
                  </div>
                </div>

                <div class="panel-title header-tool-tip-container">
                  <h1>{{ $t("invest_text10") }}</h1>
                </div>
                <h2 class="panel-subtitle">{{ $t("invest_text11") }}</h2>
              </header>
            </section>

            <section
              class="panel"
              v-show="list.length > 0 || stock_list.length > 0"
            >
              <header class="panel-header panel-header__fullheight">
                <div class="panel-title header-tool-tip-container">
                  <h1>{{ $t("invest_text16") }}</h1>
                </div>
              </header>

              <div
                class="live-feed-secondary-part-holder overflow-x-auto overflow-y-hidden"
              >
                <table
                  class="w-100 overflow-x-auto overflow-y-hidden text-nowrap"
                  style="border-spacing: 10px; border-collapse: separate"
                >
                  <thead>
                    <tr>
                      <th class="text-center">{{ $t("invest_text17") }}</th>
                      <th class="text-center">{{ $t("invest_text18") }}</th>
                      <th class="text-center">{{ $t("invest_text19") }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(l, k) in list" :key="k" class="">
                      <td class="text-center">{{ l.cname }}</td>
                      <td class="text-center">{{ l.unit }}</td>
                      <td class="text-center">
                        {{ parseFloat((l.cost * l.unit).toFixed(2)) }}
                      </td>
                    </tr>
                    <tr v-for="(l, k) in stock_list" :key="k" class="">
                      <td class="text-center">{{ l.cname }}</td>
                      <td class="text-center">{{ l.having_unit }}</td>
                      <td class="text-center">
                        {{ parseFloat((l.payment * l.having_unit).toFixed(2)) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";

export default {
  name: "Home",
  data() {
    return {
      sum_getback: 0,
      sum_cost_unit: 0,
      list: [],
      stock_list: [],
    };
  },
  watch: {},
  methods: {
    Repoort: function () {
      let param = {
        action: "sharebonus_holding",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (res.status == "success") {
          this.sum_getback = res.sum_getback;
          this.sum_cost_unit = res.sum_cost_unit;
          this.list = res.data;
        }
      });
    },
    betstock_holding: function () {
      let param = {
        action: "betstock_holding",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.stock_list = res.data;
        }
      });
    },
  },
  created() {
    this.Repoort();
    this.betstock_holding();
  },
};
</script>
