<template>
  <div>
    <div
      :class="this.newWindowurl != '' ? '' : 'finance_page deposit_temp__page'"
    >
      <div class="finance_card">
        <div class="finance_close" @click="this.$emit('fun_flag_page', false)">
          ×
        </div>
        <div class="finance_title">
          {{ $t("text_deposit_center") }}
        </div>

        <div class="finance_main">
          <div class="finance_deposit" style="margin-top: 1rem">
            <div v-if="action.toLowerCase() == 'dia'">
              <p class="text-center fw-bold">
                {{ $t("text_deposit_method") }}
              </p>
              <div
                class="d-flex justify-content-between align-items-center deposit_div_method p-3"
                :class="deposit_method == 0 ? 'border_green' : ''"
                @click="deposit_method = 0"
              >
                <img
                  src="@/assets/img/new_img/download.png"
                  class="deposit_img_method"
                />
                <div class="text-center">
                  <p class="m-0 fw-bold">{{ $t("text_deposit_1") }}</p>
                  <p class="m-0">{{ $t("text_deposit_2") }}</p>
                </div>
                <div
                  style="
                    border: 1px solid;
                    border-radius: 100%;
                    width: 25px;
                    height: 25px;
                  "
                  :class="deposit_method == 0 ? 'border_green_circle' : ''"
                ></div>
              </div>
              <div
                class="d-flex justify-content-between align-items-center deposit_div_method p-3 mt-2"
                :class="deposit_method == 1 ? 'border_green' : ''"
                @click="deposit_method = 1"
              >
                <img
                  src="@/assets/img/new_img/download-1.png"
                  class="deposit_img_method"
                />
                <div class="text-center">
                  <p class="m-0 fw-bold">{{ $t("text_deposit_3") }}</p>
                </div>
                <div
                  style="
                    border: 1px solid;
                    border-radius: 100%;
                    width: 25px;
                    height: 25px;
                  "
                  :class="deposit_method == 1 ? 'border_green_circle' : ''"
                ></div>
              </div>
            </div>

            <div v-if="action.toLowerCase() == 'dia'">
              <p class="mt-4">
                {{ $t("text_deposit_amt") }}
                ： ${{ choose_dia_amt }}
              </p>
              <p>
                {{ $t("text_deposit_dia") }}
                ：
                <img
                  src="@/assets/img/main/diamond.png"
                  style="width: 25px; height: 25px"
                />{{ choose_dia_amount }}
              </p>
            </div>

            <div v-if="action.toLowerCase() == 'coin'">
              <p class="mt-4">
                {{ $t("text_deposit_amt1") }}
                ：
                <img
                  src="@/assets/img/main/diamond.png"
                  style="width: 25px; height: 25px"
                />{{ choose_dia_amt }}
              </p>
              <p>
                {{ $t("text_deposit_dia1") }}
                ： ${{ choose_dia_amount }}
              </p>
            </div>

            <div v-if="action.toLowerCase() == 'd_dia'">
              <p class="mt-4">
                {{ $t("text_deposit_amt") }}
                ：
                <img
                  src="@/assets/img/main/new_usdt.png"
                  style="width: 25px; height: 25px"
                />{{ choose_dia_amt }} USDT
              </p>
              <p>
                {{ $t("text_deposit_dia") }}
                ：
                <img
                  src="@/assets/img/main/diamond.png"
                  style="width: 25px; height: 25px"
                />{{ choose_dia_amount }}
              </p>
            </div>

            <div class="col-10 text-white mx-auto my-3">
              <button
                class="border-0 text-white p-2 w-100 bg_light_green_btn"
                @click="
                  action.toLowerCase() == 'coin'
                    ? fun_buy_coin()
                    : action.toLowerCase() == 'dia'
                    ? fun_buy_dia()
                    : action.toLowerCase() == 'd_dia'
                    ? fun_buy_udia()
                    : ''
                "
              >
                <span>{{ $t("text_quick_pay") }}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <newWindow
      :URL="newWindowurl"
      :PAGE="'Deposit'"
      @iframe_close="iframeclosed"
      v-show="this.newWindowurl != ''"
    ></newWindow>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapActions } from "vuex";
import newWindow from "./iframe.vue";
export default {
  props: {
    choose_dia_amount: String,
    choose_dia_amt: String,
    action: String,
    mpkidx: String,
    capital: String,
  },
  data() {
    return {
      deposit_method: 0,
      newWindowurl: "",
    };
  },
  methods: {
    ...mapActions(["getBalance"]),
    fun_buy_coin: function () {
      let param = {
        action: "buy_coin",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          amount: this.choose_dia_amt,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          this.$emit("fun_flag_page", false);
          this.$MSG.API_popmsg(res.msg, "", "success");
          this.getBalance();
        } else this.$MSG.API_popmsg(res.msg, "", "error");
      });
    },
    fun_buy_dia: function () {
      this.$emit("fun_flag_page_1", "dia", this.choose_dia_amount);
    },
    fun_buy_udia: function () {
      let param = {
        action: "deposit_tmp",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          amount: this.choose_dia_amt,
          mpkidx: this.mpkidx,
          capital: this.capital,
          cname: "",
          cardno: "",
          checkchoice: "",
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (res.status == "success") {
          this.newWindowurl = res.msg;
        } else {
          this.$MSG.API_popmsg(res.msg, "", "warning");
        }
      });
    },
    iframeclosed(val) {
      this.newWindowurl = val;
    },
  },
  mounted() {},
  created() {},
  watch: {},
  components: {
    newWindow,
  },
};
</script>
