<template>
  <div class="jackpot_game">
    <div class="d-flex justify-content-start align-items-center">
      <img
        src="@/assets/img/left/jackpot_game.png"
        :title="$t('text_jackpot_game')"
        style="width: 80px"
      />
      <span class="jackpot_rank_title ms-2">{{ $t("text_jackpot_game") }}</span>
    </div>
    <div class="d-flex mt-4 jackpot_game_column" v-for="(l, kk) in 6" :key="l">
      <img
        :src="require('@/assets/img/jackpot_rank/' + l + '.png')"
        class="jackpot_rank_img"
      />
      <div class="rank2_con w-100">
        <div class="rank_con_title">
          <span>{{ $t("rank_t1_text7") }}</span
          ><span>{{ $t("rank_t1_text8") }}</span
          ><span>{{ $t("rank_t1_text9") }}</span>
        </div>
        <div class="rank_con_con" v-for="(j, k) in 3" :key="j">
          <div class="rank_con_img">
            <img
              :src="require('@/assets/img/rank/' + (k + 1) + '.png')"
              alt=""
            />
          </div>
          <div class="rank_con_center">
            <div>
              <p class="text-white fw-bolder mb-1 text-1023-size">
                User{{ random_player() }}
              </p>
              <span class="rank_con_prize"
                ><span
                  >{{ filter_number(tableData_bet[kk][k]) }}
                  <img
                    src="@/assets/img/main/diamond.png"
                    style="width: 25px; height: 25px"
                    class="me-1" /></span
              ></span>
            </div>
          </div>
          <div class="rank_con_div_prize">
            <span class="rank_con_prize"
              ><span
                >{{ filter_number(tableData_win[kk][k]) }}
                <img
                  src="@/assets/img/main/diamond.png"
                  style="width: 25px; height: 25px"
                  class="me-1" /></span
            ></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import "@/assets/style/rankt1.css";
export default {
  name: "jackpot_game",
  data() {
    return {
      tableData_bet: [
        [
          Math.ceil(Math.random() * 999999 + 1000),
          Math.ceil(Math.random() * 999999 + 1000),
          Math.ceil(Math.random() * 999999 + 1000),
        ],
        [
          Math.ceil(Math.random() * 999999 + 1000),
          Math.ceil(Math.random() * 999999 + 1000),
          Math.ceil(Math.random() * 999999 + 1000),
        ],
        [
          Math.ceil(Math.random() * 999999 + 1000),
          Math.ceil(Math.random() * 999999 + 1000),
          Math.ceil(Math.random() * 999999 + 1000),
        ],
        [
          Math.ceil(Math.random() * 999999 + 1000),
          Math.ceil(Math.random() * 999999 + 1000),
          Math.ceil(Math.random() * 999999 + 1000),
        ],
        [
          Math.ceil(Math.random() * 999999 + 1000),
          Math.ceil(Math.random() * 999999 + 1000),
          Math.ceil(Math.random() * 999999 + 1000),
        ],
        [
          Math.ceil(Math.random() * 999999 + 1000),
          Math.ceil(Math.random() * 999999 + 1000),
          Math.ceil(Math.random() * 999999 + 1000),
        ],
      ],

      tableData_win: [
        [
          Math.ceil(Math.random() * 5000 + 300),
          Math.ceil(Math.random() * 5000 + 300),
          Math.ceil(Math.random() * 5000 + 300),
        ],
        [
          Math.ceil(Math.random() * 5000 + 300),
          Math.ceil(Math.random() * 5000 + 300),
          Math.ceil(Math.random() * 5000 + 300),
        ],
        [
          Math.ceil(Math.random() * 5000 + 300),
          Math.ceil(Math.random() * 5000 + 300),
          Math.ceil(Math.random() * 5000 + 300),
        ],
        [
          Math.ceil(Math.random() * 5000 + 300),
          Math.ceil(Math.random() * 5000 + 300),
          Math.ceil(Math.random() * 5000 + 300),
        ],
        [
          Math.ceil(Math.random() * 5000 + 300),
          Math.ceil(Math.random() * 5000 + 300),
          Math.ceil(Math.random() * 5000 + 300),
        ],
        [
          Math.ceil(Math.random() * 5000 + 300),
          Math.ceil(Math.random() * 5000 + 300),
          Math.ceil(Math.random() * 5000 + 300),
        ],
      ],
    };
  },
  created() {},
  methods: {
    random_player() {
      return Math.ceil(Math.random() * 10000 + 10000) + "***";
    },
    filter_number: function (value) {
      if (!value) return 0;
      const valueArray = value.toString().split(".");
      let regex = /(\d)(?=(?:\d{3})+$)/g;
      if (valueArray.length === 2) {
        const intPart = valueArray[0].toString().replace(regex, "$1,");
        let floatPart = "";
        floatPart = valueArray[1].toString();
        return intPart + "." + floatPart;
      } else {
        const intPart = value.toString().replace(regex, "$1,");
        return intPart;
      }
    },
    num_sort: function (value) {
      value.sort(function (a, b) {
        return b - a;
      });
    },
  },
  watch: {},
  computed: {},
  components: {},
  mounted() {
    for (var i = 0; i < this.tableData_bet.length; i++)
      this.num_sort(this.tableData_bet[i]);

    for (var j = 0; j < this.tableData_win.length; j++)
      this.num_sort(this.tableData_win[j]);
  },
};
</script>
