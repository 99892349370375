<template>
  <div>
    <div
      class="hover-container mission-calendar calendar"
      :style="!flag_show ? 'display: none' : 'display: flex'"
    >
      <div class="modal_cal">
        <div class="container">
          <div
            id="svga-box"
            style="text-align: left"
            :style="!flag_box_show ? 'display: none' : 'display: block'"
            @click="flag_open_bpx = true"
          >
            <img src="@/assets/img/sign_up/gift_closed.png" alt="" />

            <div v-show="flag_open_bpx">
              <div class="show-nub">
                <div class="numbers-box">
                  <div :data-no="gift_datano">
                    <span style="font-size: 16px"
                      ><img
                        src="@/assets/img/main/diamond.png"
                        style="width: 25px; height: 25px"
                        class="me-1"
                    /></span>
                  </div>
                </div>
                <div class="box-btn" @click="flag_box_show = false">
                  {{ $t("shared_comfirm").toUpperCase() }}
                </div>
              </div>
              <img src="@/assets/img/sign_up/gift_open.gif" alt="" />
            </div>
          </div>

          <div class="header">
            <div class="month-selector">
              <svg
                class="svg-inline--fa fa-caret-left fa-w-6"
                :class="month_val != 1 ? 'caret_color' : ''"
                @click="arrow_previousMonth()"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="caret-left"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 192 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M192 127.338v257.324c0 17.818-21.543 26.741-34.142 14.142L29.196 270.142c-7.81-7.81-7.81-20.474 0-28.284l128.662-128.662c12.599-12.6 34.142-3.676 34.142 14.142z"
                ></path>
              </svg>

              <span class="month"
                >{{ this.year_val }} /
                {{
                  this.month_val > 9 ? this.month_val : "0" + this.month_val
                }}</span
              >
              <svg
                class="svg-inline--fa fa-caret-right fa-w-6"
                :class="month_val != 12 ? 'caret_color' : ''"
                @click="arrow_nextMonth()"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="caret-right"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 192 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M0 384.662V127.338c0-17.818 21.543-26.741 34.142-14.142l128.662 128.662c7.81 7.81 7.81 20.474 0 28.284L34.142 398.804C21.543 411.404 0 402.48 0 384.662z"
                ></path>
              </svg>
            </div>

            <div
              class="mps-close"
              @click="
                flag_show = false;
                click_gift = 'N';
                this.$emit('close_sign_main', false);
              "
            >
              <svg
                class="svg-inline--fa fa-times fa-w-11"
                aria-hidden="true"
                focusable="false"
                data-prefix="fas"
                data-icon="times"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 352 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"
                ></path>
              </svg>
            </div>
          </div>
          <div class="calendar">
            <div>
              <div class="calendar-weekdays" style="width: 100%; display: flex">
                <span class="date-box">{{ $t("week_7") }}</span>
                <span class="date-box">{{ $t("week_1") }}</span>
                <span class="date-box">{{ $t("week_2") }}</span>
                <span class="date-box">{{ $t("week_3") }}</span>
                <span class="date-box">{{ $t("week_4") }}</span>
                <span class="date-box">{{ $t("week_5") }}</span>
                <span class="date-box">{{ $t("week_6") }}</span>
              </div>
              <div class="calendar_con" v-html="str"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import "@/assets/style/sign_up.css";
export default {
  data() {
    return {
      flag_btn: false,
      flag_month: -1,
      today_val: new Date(),
      date_val: "",
      day_val: "",
      month_val: "",
      year_val: "",
      str: "",
      click_gift: "N",
      flag_show: false,
      flag_box_show: false,
      flag_open_bpx: false,
      gift_datano: 0,
    };
  },
  methods: {
    gift_show_click() {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        this.date_val = new Date(this.today_val).getDate();
        this.day_val = new Date(this.today_val).getDay();
        this.month_val = new Date(this.today_val).getMonth() + 1;
        this.year_val = new Date(this.today_val).getFullYear();
        this.click_gift = "Y";
        var Today = new Date();
        this.flag_month = Today.getMonth() + 1;
        this.getCalendar();
        this.flag_show = true;
      }
    },
    getCalendar: function () {
      var len = this.getLen();
      var d = new Date(this.year_val, this.month_val - 1, 1); //JS 表達月份(month) 是從 0 到 11，0是一月；11是十二月
      var dfw = d.getDay(); //0表示星期日；1表示星期一；2表示星期二
      var arr = new Array();
      var tem = 0;
      this.str = "";
      for (var i = 0; i < 6; i++) {
        arr[i] = new Array();
        for (var j = 0; j < 7; j++) {
          tem++;
          if (tem - dfw > 0 && tem - dfw <= len) {
            arr[i][j] = tem - dfw;
          } else {
            arr[i][j] = "";
          }
        }
      }

      for (var k = 0; k < 6; k++) {
        if (k == 5 && arr[k][0] == "") continue;
        for (var m = 0; m < arr[k].length; m++) {
          if (arr[k][m] == "") {
            this.str +=
              '<div class="date-box"><div class="date">' +
              arr[k][m] +
              "</div></div>";
            continue;
          }
          this.str += '<div class="date-box">';
          this.str += '<div class="date">' + arr[k][m] + "</div>";
          this.str +=
            '<div class="stamp"><div id="day' + arr[k][m] + '"></div></div>';
          this.str += "</div>";
        }
        this.str += '<hr class="line_bottom"/>';
      }
      if (this.month_val <= this.flag_month) this.sign_up_api();
    },
    getLen: function () {
      if (this.month_val == 2) {
        if (this.isLeap()) {
          return 29;
        }
        return 28;
      }
      if (this.month_val < 8) {
        if (this.month_val % 2 > 0) {
          return 31;
        }
        return 30;
      }
      if (this.month_val % 2 > 0) {
        return 30;
      }
      return 31;
    },
    isLeap: function () {
      var year = this.year_val;
      if (year % 4 == 0 && year % 100 > 0) {
        return true;
      }
      if (year % 400 == 0 && year % 3200 > 0) {
        return true;
      }
      return false;
    },
    nextMonth: function () {
      if (this.month_val == 12) {
        this.year_val++;
        this.month_val = 0;
      }
      this.month_val++;
    },
    previousMonth: function () {
      if (this.month_val == 1) {
        this.year_val--;
        this.month_val = 13;
      }
      return this.month_val--;
    },
    arrow_previousMonth() {
      if (this.month_val - 1 > 0) {
        this.previousMonth();
        this.getCalendar();
      }
    },
    arrow_nextMonth() {
      if (this.month_val < 12) {
        this.nextMonth();
        this.getCalendar();
      }
    },
    sign_up_api() {
      let param = {
        action: "sign_up",
        body: {
          uid: localStorage.getItem("uid"),
          session_code: Cookies.get("code"),
          month: this.month_val,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        if (res.status == "success") {
          if (res.isfirstsigntoday || this.click_gift == "Y") {
            if (this.month_val == this.flag_month && res.is_get_gift == true) {
              this.flag_box_show = true;
              if (res.gift == "50") this.gift_datano = "1";
              else if (res.gift == "100") this.gift_datano = "2";
              else if (res.gift == "150") this.gift_datano = "3";
            }
            for (var i = 1; i <= this.getLen(); i++) {
              var str_class = "";
              switch (res["day" + i]) {
                case "Y":
                  str_class = "sign_y";
                  break;
                case "N":
                  str_class = "sign_x";
                  break;
                default:
                  str_class = "block_card1";
                  break;
              }
              document.getElementById("day" + i).classList.add(str_class);
            }
          }
        }
      });
    },
  },
  created() {
    this.gift_show_click();
  },
};
</script>
