<template>
  <div>
    <div class="winner-cotainer">
      <div class="winner-title">
        <img
          src="@/assets/img/game_category/icon-winner.png"
          class="winner-t-img"
        /><span>{{ $t("text_winner_title") }}</span>
      </div>
      <div class="winner-content">
        <div class="winner-div" id="winner-div">
          <div :style="{ transform: 'translateY(-' + scrollTop + 'px)' }">
            <div
              class="winner-item-cotainer d-flex flex-wrap justify-content-between"
              id="winner-item-cotainer"
              v-for="j in 2"
              :key="j"
            >
              <div
                class="winner-item d-flex align-items-center justify-content-between c-pointer py-2"
                v-for="l in game_all_random"
                :key="l"
              >
                <div class="winner-game">
                  <img v-lazy="l[0].iconName" alt="" />
                  <div class="winner-info d-flex flex-column align-items-start">
                    <span class="winner-name">{{ l[0].userid }}</span
                    ><span>{{ l[0].gamename }}</span>
                  </div>
                </div>
                <div
                  class="winner-amount d-flex align-items-center justify-content-between position-relative px-2"
                >
                  <img
                    src="@/assets/img/main/winner-coin.png"
                    alt=""
                    class="winner-coin-img"
                  />
                  <div class="winner-amount-div">
                    &nbsp;{{ filter_number(l[0].win) }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
export default {
  props: {},
  data() {
    return {
      timer: "",
      scrollTop: "",
      // boxHeight: "",
      scrollHeight: "",
      new_arr_all: [],
      index_0_arr: [],
      old_arr: [
        [
          {
            userid: "12363***",
            win: 337499,
            gamename: "金龟神弩",
            iconName: "http://gameweb.metaversement.io/images/bbin/5206.png",
          },
        ],
        [
          {
            userid: "18448***",
            win: 423429,
            gamename: "鱼虾蟹开了",
            iconName: "http://gameweb.metaversement.io/images/bbin/5200.png",
          },
        ],
        [
          {
            userid: "19207***",
            win: 431412,
            gamename: "街头美食",
            iconName: "http://gameweb.metaversement.io/images/bbin/5199.png",
          },
        ],
        [
          {
            userid: "17116***",
            win: 233676,
            gamename: "下龙湾传说",
            iconName: "http://gameweb.metaversement.io/images/bbin/5198.png",
          },
        ],
        [
          {
            userid: "10176***",
            win: 305014,
            gamename: "蜂赢百搭",
            iconName: "http://gameweb.metaversement.io/images/bbin/5195.png",
          },
        ],
        [
          {
            userid: "15645***",
            win: 424428,
            gamename: "黄金财神",
            iconName: "http://gameweb.metaversement.io/images/bbin/5194.png",
          },
        ],
        [
          {
            userid: "12503***",
            win: 334333,
            gamename: "急冻世界",
            iconName: "http://gameweb.metaversement.io/images/bbin/5192.png",
          },
        ],
        [
          {
            userid: "17608***",
            win: 344546,
            gamename: "猪宝满满",
            iconName: "http://gameweb.metaversement.io/images/bbin/5191.png",
          },
        ],
        [
          {
            userid: "14733***",
            win: 191831,
            gamename: "牛运当头",
            iconName: "http://gameweb.metaversement.io/images/bbin/5189.png",
          },
        ],
        [
          {
            userid: "19952***",
            win: 434389,
            gamename: "新年快乐",
            iconName: "http://gameweb.metaversement.io/images/bbin/5188.png",
          },
        ],
        [
          {
            userid: "14056***",
            win: 45611,
            gamename: "忍者大师",
            iconName: "http://gameweb.metaversement.io/images/bbin/5187.png",
          },
        ],
        [
          {
            userid: "11545***",
            win: 442578,
            gamename: "七福报喜",
            iconName: "http://gameweb.metaversement.io/images/bbin/5186.png",
          },
        ],
        [
          {
            userid: "18299***",
            win: 217087,
            gamename: "扭转钱坤",
            iconName: "http://gameweb.metaversement.io/images/bbin/5183.png",
          },
        ],
        [
          {
            userid: "11330***",
            win: 143085,
            gamename: "抖音DJ",
            iconName: "http://gameweb.metaversement.io/images/bbin/5182.png",
          },
        ],
        [
          {
            userid: "15014***",
            win: 135340,
            gamename: "野蛮战国",
            iconName: "http://gameweb.metaversement.io/images/bbin/5176.png",
          },
        ],
        [
          {
            userid: "18368***",
            win: 130838,
            gamename: "偏财神",
            iconName: "http://gameweb.metaversement.io/images/bbin/5172.png",
          },
        ],
        [
          {
            userid: "18403***",
            win: 329906,
            gamename: "海底传奇",
            iconName: "http://gameweb.metaversement.io/images/bbin/5170.png",
          },
        ],
        [
          {
            userid: "15925***",
            win: 426544,
            gamename: "中国好声音",
            iconName: "http://gameweb.metaversement.io/images/bbin/5169.png",
          },
        ],
        [
          {
            userid: "18383***",
            win: 45818,
            gamename: "葫芦娃",
            iconName: "http://gameweb.metaversement.io/images/bbin/5166.png",
          },
        ],
        [
          {
            userid: "12444***",
            win: 13596,
            gamename: "好事成双",
            iconName: "http://gameweb.metaversement.io/images/bbin/5164.png",
          },
        ],
      ],
    };
  },
  methods: {
    new_scroll() {
      if (this.scrollTop < this.scrollHeight) {
        this.scrollTop = this.scrollTop + 1.5;
      } else {
        this.scrollTop = 0;
      }
    },
    filter_number: function (value) {
      if (!value) return 0;
      const valueArray = value.toString().split(".");
      let regex = /(\d)(?=(?:\d{3})+$)/g;
      if (valueArray.length === 2) {
        const intPart = valueArray[0].toString().replace(regex, "$1,");
        let floatPart = "";
        floatPart = valueArray[1].toString();
        return intPart + "." + floatPart;
      } else {
        const intPart = value.toString().replace(regex, "$1,");
        return intPart;
      }
    },
    ...mapActions(["getGameList"]),
    gamelist: function (t) {
      var gametype =
        t.toUpperCase().indexOf("A1-") != -1
          ? t.toUpperCase().split("-")[0]
          : t;
      var type =
        t.toUpperCase().indexOf("A1-") != -1
          ? t.toUpperCase().split("-")[1]
          : "all";

      let param = {
        action: "GameLobby",
        body: {
          gametype: gametype,
          type: type,
          uid: localStorage.getItem("uid"),
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var arr_all = [];
        for (var j = 0; j < 20; j++) {
          arr_all.push(res.list[j]);
        }

        var insert_data = [];
        arr_all.forEach((v) => {
          insert_data = [
            {
              userid: Math.ceil(Math.random() * 10000 + 10000) + "***",
              win: Math.ceil(Math.random() * 500000 + 1000),
              gamename: v.gamename,
              iconName: "http://gameweb.metaversement.io/" + v["image"],
            },
          ];
          this.new_arr_all.push(insert_data);
        });
      });
    },
    random_game() {
      var ele_id = document.getElementById("winner-div");
      this.scrollTop = ele_id.scrollTop;

      // this.boxHeight = window.getComputedStyle(ele_id).getPropertyValue("height"); //取得div的高度
      // this.boxHeight = parseInt(this.boxHeight);

      this.scrollHeight = document.getElementById(
        "winner-item-cotainer"
      ).scrollHeight;

      this.timer = setInterval(() => {
        this.new_scroll();
      }, 30);
    },
  },
  mounted() {
    this.random_game();
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  created() {
    this.gamelist(this.all_game["slot"][0]);
  },
  computed: {
    ...mapGetters(["all_game", "list"]),
    pp_list() {
      return function (jj) {
        var new_arr_2 = [];
        var arr_2 = JSON.parse(
          localStorage.getItem(jj.toLowerCase() + "_all_gamelist")
        );
        for (var j = 0; j < 20; j++) {
          new_arr_2.push(arr_2[j]);
        }
        return new_arr_2;
      };
    },
    game_all_random() {
      if (this.new_arr_all != null && this.new_arr_all != "")
        return this.new_arr_all;
      else return this.old_arr;
    },
  },
  components: {},
  watch: {},
};
</script>
