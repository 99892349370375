<template>
  <div class="col-xl-12 header_mt" style="height: 100%">
    <iframe
      id="newWindow"
      :src="this.s_url"
      width="100%"
      height="100%"
      style="min-height: 500px"
    >
    </iframe>
  </div>
</template>

<script>
import Cookies from "js-cookie";

export default {
  name: "shop_store",
  data() {
    return {
      s_url: "",
    };
  },
  created() {
    if (
      Cookies.get("code") != null &&
      Cookies.get("code") != "" &&
      localStorage.getItem("uid") != null &&
      localStorage.getItem("uid") != ""
    ) {
      this.open_shop_new();
    }
  },
  components: {},
  computed: {},
  watch: {},
  methods: {
    open_shop_new: function () {
      if (
        Cookies.get("code") == null ||
        Cookies.get("code") == "" ||
        localStorage.getItem("uid") == null ||
        localStorage.getItem("uid") == ""
      ) {
        this.$store.commit("islogin", true);
        this.$store.dispatch("logout_public");
      } else {
        let param = {
          action: "stage_balance",
          body: {
            uid: localStorage.getItem("uid"),
            ip: localStorage.getItem("ip"),
            session_code: Cookies.get("code"),
            device: "",
            gametype: "BUYSHOP",
            gamekind: "shop",
            gameid: "",
            mode: "",
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          if (res.includes("shop.starlink.pw")) this.s_url = res;
          else this.$MSG.API_popmsg(this.$t("try_again"), "", "error");
        });
      }
    },
  },
};
</script>
