<template>
  <div class="home_game_casino jackpot_game home_vip_page">
    <div class="d-flex justify-content-between align-items-center">
      <div class="game_logo_img fw-bolder my-3">
        <img src="@/assets/img/left/service.png" alt="" style="width: 50px" />
        <div class="d-flex flex-column">
          <span class="jackpot_rank_title ms-2">
            {{ $t("left_menu_text8").toLowerCase() }}</span
          >
        </div>
      </div>
    </div>
    <div class="">
      <p class="mt-3 mb-2" style="font-size: 20px">{{ $t("service_text1") }}</p>
      <div
        class="d-flex justify-content-start justify-content-md-between align-items-start align-items-md-center py-3 flex-column flex-md-row gap-767-25"
        style="border: 1px solid #fff"
      >
        <div
          class="d-flex justify-content-center align-items-center c-pointer col-12 col-md-6 ps-2 border-r-md-1"
        >
          <a
            class="d-flex justify-content-start justify-content-md-center align-items-center c-pointer"
            style="width: 100%; text-decoration: none; color: #e5e5e7"
          >
            <div
              class="d-flex justify-content-between align-items-center flex-column me-4 w-767-25"
            >
              <img
                src="@/assets/img/new_img/line.png"
                style="width: 35px"
                class="mb-2"
              />
              <span class="text-new-dark">LINE</span>
            </div>
            <span class="text-new-dark">{{ $store.state.service.qq.telegram }}</span></a
          >
        </div>
        <div
          class="d-flex justify-content-center align-items-center c-pointer col-12 col-md-6 ps-2"
        >
          <a
            :href="'mailto:' + $store.state.service.qq.email"
            class="d-flex justify-content-start justify-content-md-center align-items-center c-pointer"
            style="width: 100%; text-decoration: none; color: #e5e5e7"
          >
            <div
              class="d-flex justify-content-between align-items-center flex-column me-4 w-767-25"
            >
              <img
                src="@/assets/img/new_img/service_email.png"
                style="width: 35px"
                class="mb-2"
              />
              <span class="text-new-dark">EMAIL</span>
            </div>
            <span class="text-new-dark">{{ $store.state.service.qq.email }}</span>
          </a>
        </div>
      </div>
    </div>

    <dwrank class="p-0" />
    <!-- <qaview /> -->
  </div>
</template>
<script>
import { mapActions } from "vuex";
import dwrank from "./dw_rank.vue";
// import qaview from "./service_common_qa.vue";
export default {
  props: {},
  data() {
    return {};
  },
  methods: {
    ...mapActions(["getService"]),
    link: function (e) {
      var url = "";
      switch (e) {
        case "service":
          url = this.$store.state.service.qq.qq;
          break;
        default:
          url = this.$store.state.service.qq[e];
          break;
      }
      window.open(url);
    },
  },
  mounted() {},
  created() {
    this.getService();
  },
  watch: {},
  components: {
    dwrank,
    // qaview,
  },
};
</script>
