<template>
  <div>
    <div class="signup-popup-container">
      <span class="popup-close-btn-signup" @click="close_reg()"
        ><svg
          xmlns="http://www.w3.org/2000/svg"
          width="13"
          height="13"
          viewBox="0 0 13 13"
        >
          <path
            fill="#eee"
            fill-rule="evenodd"
            d="M12.684 1.85c.211-.211.316-.467.316-.767s-.105-.555-.316-.767A1.045 1.045 0 0011.917 0c-.3 0-.556.105-.768.316L6.5 4.966 1.85.315A1.045 1.045 0 001.084 0c-.3 0-.555.105-.767.316A1.045 1.045 0 000 1.083c0 .3.105.556.316.768L4.966 6.5l-4.65 4.65a1.045 1.045 0 00-.316.767c0 .3.105.555.316.767.212.211.468.316.767.316.3 0 .556-.105.768-.316L6.5 8.034l4.65 4.65c.211.211.467.316.767.316s.555-.105.767-.316c.211-.212.316-.468.316-.767 0-.3-.105-.556-.316-.768L8.034 6.5l4.65-4.65z"
          ></path>
        </svg>
      </span>
      <div>
        <div class="signup-desk">
          <div class="sign-up-container">
            <div class="login-popup-logo">
              <img
                src="@/assets/logo.png"
                class="sign-up-container__logo"
                alt="Logo"
              />
            </div>
            <div class="sign-up-container__message"></div>
            <div class="sign-up-container__form">
              <form method="POST" @submit="Register" ref="ref_required">
                <div class="form_grid">
                  <div class="single-form-item reg_cScreenname">
                    <label>{{ $t("text_userid") }}</label
                    ><input
                      type="text"
                      :placeholder="$t('text_tip_userid')"
                      v-model="uid"
                      maxlength="10"
                      minlength="4"
                      required="required"
                    />
                    <div
                      class="px-1 py-2"
                      style="
                        position: absolute;
                        top: 20%;
                        right: 7px;
                        border: 1px solid #ffcc00;
                        color: #ffcc00;
                      "
                      @click="checkuserid()"
                    >
                      {{ $t("text_check_userid") }}
                    </div>
                  </div>
                  <div class="single-form-item reg_password">
                    <label>{{ $t("passwordTitle") }}</label
                    ><input
                      :type="flag_eye ? 'text' : 'password'"
                      :placeholder="$t('text_tip_pw')"
                      v-model="pwd"
                      minlength="6"
                      maxlength="12"
                      required="required"
                    />
                    <span class="validating-icon" @click="flag_eye = !flag_eye">
                      <img
                        :src="
                          flag_eye
                            ? require('@/assets/img/new_img/open_eye.png')
                            : require('@/assets/img/new_img/close_eye.png')
                        "
                      />
                    </span>
                  </div>
                  <div class="single-form-item reg_password">
                    <label>{{ $t("comfpwd") }}</label
                    ><input
                      :type="flag_eye1 ? 'text' : 'password'"
                      :placeholder="$t('text_tip_pw')"
                      v-model="pwd1"
                      minlength="6"
                      maxlength="12"
                      required="required"
                    />
                    <span
                      class="validating-icon"
                      @click="flag_eye1 = !flag_eye1"
                    >
                      <img
                        :src="
                          flag_eye1
                            ? require('@/assets/img/new_img/open_eye.png')
                            : require('@/assets/img/new_img/close_eye.png')
                        "
                      />
                    </span>
                  </div>
                  <div class="single-form-item reg_password">
                    <label>{{ $t("reg_trade_pw_title") }}</label
                    ><input
                      :type="flag_eye2 ? 'text' : 'password'"
                      :placeholder="$t('reg_trade_pw_tips')"
                      v-model="trade_pwd"
                      minlength="4"
                      maxlength="6"
                      required="required"
                    />
                    <span
                      class="validating-icon"
                      @click="flag_eye2 = !flag_eye2"
                    >
                      <img
                        :src="
                          flag_eye2
                            ? require('@/assets/img/new_img/open_eye.png')
                            : require('@/assets/img/new_img/close_eye.png')
                        "
                      />
                    </span>
                  </div>
                  <div
                    class="single-form-item reg_cEmail"
                    v-if="set_regi.have_email"
                  >
                    <label>{{ $t("email") }}</label
                    ><input
                      type="email"
                      :placeholder="$t('text_et')"
                      v-model="email"
                      maxlength="50"
                      required="required"
                    />
                  </div>
                  <div
                    class="single-form-item reg_cEmail"
                    v-if="set_regi.have_phone"
                  >
                    <label>{{ $t("reg_phone") }}</label
                    ><input
                      type="text"
                      :placeholder="$t('text_tip_phonenum')"
                      v-model="phone"
                      minlength="11"
                      maxlength="11"
                      required="required"
                    />
                  </div>
                  <!-- <div class="single-form-item reg_cEmail">
                    <label>{{ $t("reg_telecom_provider") }}</label>
                    <select v-model="telecom_provider" class="select-reg">
                      <option value="SKT" selected>SKT</option>
                      <option value="KT">KT</option>
                      <option value="LGU+">LGU+</option>
                      <option value="SKT 알뜰폰">
                        SKT {{ $t("reg_telecom_title") }}
                      </option>
                      <option value="KT 알뜰폰">
                        KT {{ $t("reg_telecom_title") }}
                      </option>
                      <option value="LGU+ 알뜰폰">
                        LGU+ {{ $t("reg_telecom_title") }}
                      </option>
                    </select>
                  </div> -->
                  <div class="single-form-item reg_cEmail">
                    <label>{{ $t("reg_canme_title") }}</label
                    ><input
                      type="text"
                      :placeholder="$t('reg_canme_subtitle')"
                      v-model="cname"
                      maxlength="50"
                      required="required"
                    />
                  </div>
                  <!-- <div class="single-form-item reg_cEmail">
                    <label>{{ $t("add_Bank") }}</label>
                    <select v-model="bankname" class="select-reg">
                      <option value="카카오뱅크" selected>카카오뱅크</option>
                      <option value="농협은행">농협은행</option>
                      <option value="국민은행">국민은행</option>
                      <option value="신한은행">신한은행</option>
                      <option value="우리은행">우리은행</option>
                      <option value="새마을금고">새마을금고</option>
                      <option value="하나은행">하나은행</option>
                      <option value="부산은행">부산은행</option>
                      <option value="우체국">우체국</option>
                      <option value="제주은행">제주은행</option>
                      <option value="전북은행">전북은행</option>
                      <option value="SC제일은행">SC제일은행</option>
                      <option value="광주은행">광주은행</option>
                      <option value="한국씨티은행">한국씨티은행</option>
                    </select>
                  </div> -->
                  <!-- <div class="single-form-item reg_cEmail">
                    <label>{{ $t("add_Card") }}</label
                    ><input
                      type="text"
                      :placeholder="$t('reg_card_subtitle')"
                      v-model="bankno"
                      maxlength="50"
                      required="required"
                    />
                  </div> -->
                  <div
                    class="single-form-item reg_cEmail"
                    v-if="set_regi.have_birthday"
                  >
                    <label>{{ $t("text_birth") }}</label
                    ><input
                      type="text"
                      :placeholder="$t('text_birth_tips')"
                      v-model="birthday"
                      maxlength="8"
                      required="required"
                    />
                  </div>
                  <div class="">
                    <!-- <button type="button" @click="flag_ref = !flag_ref">
                    {{ $t("referenceCodeTitle")
                    }}<span :class="flag_ref ? 'expanded' : ''">{{
                      flag_ref ? "-" : "+"
                    }}</span>
                  </button> -->
                    <div class="single-form-item reg_affiliateCode">
                      <label>{{ $t("referenceCodeTitle") }}</label
                      ><input
                        type="text"
                        class="invalid"
                        :placeholder="$t('referenceCodeTitle')"
                        v-model="refcode"
                        :disabled="dis_refcode"
                      />
                    </div>
                  </div>
                  <div class="single-form-item reg_cEmail">
                    <label>{{ $t("text_Captcha") }}</label
                    ><input
                      type="text"
                      v-model="captcha"
                      maxlength="4"
                      minlength="4"
                      required="required"
                      ref="captcha"
                    />
                    <captchaview ref="captgetcode" />
                  </div>
                </div>
                <!-- <div class="reg_agree">
                  <div class="valid">
                    <span>{{ $t("text_agree_term") }}</span>
                  </div>
                </div> -->
                <div class="btn-holder">
                  <input
                    type="submit"
                    id="regSubmit1"
                    :value="$t('text_join')"
                  />
                </div>
              </form>
            </div>
            <div class="sign-up-container__login-message">
              {{ $t("already")
              }}<span
                @click="
                  close_reg();
                  open_login('islogin');
                "
                >{{ $t("login1") }}</span
              >
            </div>
            <div class="sign-up-container__login-message c-pointer">
              <p>
                <span @click="close_reg()" style="color: #8394a0">
                  {{ $t("text_go_home") }}</span
                >
              </p>
            </div>
            <!-- <div class="sign-up-container__terms">条款和条件</div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Cookies from "js-cookie";
import { mapGetters } from "vuex";
import api from "../components/http/api";
import captchaview from "./Home/captcha.vue";

export default {
  name: "register",
  data() {
    return {
      set_regi: "",
      uid: "",
      pwd: "",
      pwd1: "",
      trade_pwd: "",
      cname: "",
      phone: "",
      birthday: "",
      email: "",
      refcode: localStorage.getItem("introducer") ?? "",
      dis_refcode:
        localStorage.getItem("introducer") != null &&
        localStorage.getItem("introducer") != ""
          ? true
          : false,
      list: [
        "@gmail.com",
        "@hotmail.com",
        "@outlook.com",
        "@live.com",
        "@yahoo.com",
        "@icloud.com",
        "@facebook.com",
        "@me.com",
      ],
      emil_index: null,
      flag_ref: false,
      flag_eye: false,
      flag_eye1: false,
      flag_eye2: false,
      captcha: "",
      telecom_provider: "",
      bankname: "",
      bankno: "",
    };
  },
  async created() {
    await this.GetIP();
    if (localStorage.getItem("ip") != null) {
      if (localStorage.getItem("ip").length < 7) {
        this.$MSG.API_popmsg(this.$t("text_ip"), "", "error");
      }
    }
    this.Set_Register();
  },
  mounted() {
    this.new_required_fun();
  },
  updated() {
    this.new_required_fun();
  },
  methods: {
    async GetIP() {
      await api.getIP().then((res) => {
        localStorage.setItem("ip", res);
      });
    },
    Set_Register: function () {
      let param = {
        action: "Set_Register",
      };
      this.$store.dispatch("getpost", param).then((res) => {
        this.set_regi = res;
      });
    },
    Register: function (e) {
      e.preventDefault();
      var re = /^[a-zA-Z0-9]+$/;
      var re_trade_pw = /^([0-9]{4,6})+$/;

      var error_str = "";
      if (this.pwd != this.pwd1) {
        this.pwd = this.pwd1 = "";
        error_str +=
          this.$t("Member_registerCheckPasswordPatternErrMessage") + "</br>";
      }
      if (!re.test(this.uid) || this.uid.length < 4) {
        error_str +=
          this.$t("Member_registerAccountPatternErrMessage") + "</br>";
      }
      if (!re.test(this.pwd) || this.pwd.length < 6) {
        error_str +=
          this.$t("Member_registerPasswordPatternErrMessage") + "</br>";
      }
      if (!re_trade_pw.test(this.trade_pwd) || this.trade_pwd.length < 4) {
        error_str +=
          this.$t("Member_registerTradePasswordPatternErrMessage") + "</br>";
      }
      if (!this.validateEmail(this.email) && this.set_regi.have_email) {
        error_str += this.$t("Member_registerEmailPatternErrMessage") + "</br>";
      }
      // if (!this.vali_cardnum(this.bankno)) {
      //   error_str +=
      //     this.$t("add_Card") + "：" + this.$t("reg_card_subtitle") + "</br>";
      // }
      if (!this.check_birth(this.birthday) && this.set_regi.have_birthday) {
        error_str +=
          this.$t("text_birth") + "：" + this.$t("text_birth_tips") + "</br>";
      }
      if (!this.checkcode()) {
        error_str += this.$t("text_error_cap") + "</br>";
      }
      if (error_str != null && error_str != "")
        this.$MSG.API_popmsg(error_str, "", "error");
      else {
        this.checkForm();
      }
    },
    checkForm: function () {
      localStorage.setItem("device", this.$Utils.isMobile());
      let param = {
        action: "Register",
        body: {
          uid: this.uid,
          pwd: this.pwd,
          cname: this.cname,
          email: this.email,
          tel: this.phone,
          birthday: this.birthday,
          wechat: "",
          qq: "",
          regdomain: document.location.origin,
          affiliate: localStorage.getItem("agent") ?? "",
          // sms: this.sms,
          ip: localStorage.getItem("ip"),
          device: localStorage.getItem("device"),
          token: "",
          introducer: this.refcode,
          withdrawpw: this.trade_pwd,
          phone: this.telecom_provider,
          bank: this.bankname,
          cardno: this.bankno,
        },
      };
      this.$store.dispatch("getpost", param).then((res) => {
        var status = res.status;
        if (status == "success") {
          localStorage.removeItem("introducer");
          Cookies.set("code", res.session_code);
          localStorage.setItem("uid", this.uid);
          localStorage.setItem("utype", 0);
          localStorage.setItem("utypeid", 10);
          localStorage.setItem("clubid", res.clubid);
          localStorage.setItem("big_logo", 1);
          this.$MSG.API_popmsg(
            this.$t("Member_registerSuccessTitle"),
            "regi_success",
            "success"
          );
        } else {
          this.$MSG.API_popmsg(status, "", "error");
        }
      });
    },
    validateEmail(value) {
      if (!/^[^@]+@\w+(\.\w+)+\w$/.test(value)) {
        return false;
      } else {
        return true;
      }
    },
    checkcode: function () {
      return this.$refs.captgetcode.getdata() == this.captcha ? true : false;
    },
    emailvalselectfun: function (e, val) {
      if (e == "mouse") {
        this.email = this.email.split("@")[0] + val;
        document.getElementById("email_select").style.display = "none";
      } else if (e == "down") {
        if (
          this.emil_index == null ||
          this.emil_index == this.emailselectcompute.length - 1
        ) {
          this.emil_index = 0;
        } else {
          this.emil_index = this.emil_index + 1;
        }
      } else if (e == "up") {
        if (this.emil_index == null || this.emil_index == 0) {
          this.emil_index = this.emailselectcompute.length - 1;
        } else {
          this.emil_index = this.emil_index - 1;
        }
      } else if (e == "key") {
        if (this.email != null && this.email != "") {
          if (this.emil_index != null) {
            this.email =
              this.email.split("@")[0] +
              this.emailselectcompute[this.emil_index];
          }
          document.getElementById("email_select").style.display = "none";
        }
      } else {
        if (this.email != "" && this.email != null) {
          document.getElementById("email_select").style.display = "flex";
        } else {
          document.getElementById("email_select").style.display = "none";
        }
      }
    },
    link: function (e) {
      var url = "";
      switch (e) {
        case "service":
          url = this.$store.state.service.qq.qq;
          break;
        default:
          url = "";
          break;
      }
      window.open(url);
    },
    close_reg: function () {
      this.$store.commit("isreg", false);
    },
    open_login: function (id) {
      this.$store.commit(id, true);
      this.$store.dispatch("logout_public");
    },
    new_required_fun: function () {
      var el = this.$refs.ref_required.querySelectorAll(
        ".single-form-item input[required]"
      );

      el.forEach((v) => {
        v.oninput = v.setCustomValidity("");
        if (!v.validity.valid && (v.value == "" || v.value == null)) {
          v.oninvalid = v.setCustomValidity(this.$t("text_tip_required_new"));
        }
      });
    },
    vali_cardnum(value) {
      var pattern = new RegExp("[0-9]");
      var a_k_url = value.split("");
      var flag_p;
      a_k_url.forEach((element) => {
        if (pattern.test(element)) {
          if (flag_p != false) flag_p = true;
        } else flag_p = false;
      });
      return flag_p;
    },
    checkuserid: function () {
      var re = /^[a-zA-Z0-9]+$/;
      if (!re.test(this.uid) || this.uid.length < 4) {
        this.$MSG.API_popmsg(
          this.$t("text_userid") + this.$t("error_required"),
          "",
          "error"
        );
      } else {
        let param = {
          action: "Check_userid",
          body: {
            uid: this.uid,
          },
        };
        this.$store.dispatch("getpost", param).then((res) => {
          var status = res.status;
          if (status == "success") {
            this.$MSG.API_popmsg(res.msg, "", "success");
          } else {
            this.$MSG.API_popmsg(res.msg, "", "error");
          }
        });
      }
    },
    check_birth: function (bir) {
      // var pre_bir = bir.substring(0, 6);
      var re = /^([0-9]{6})([-]{1})([a-zA-Z0-9]{1})+$/;
      if (!re.test(bir)) {
        return false;
      } else {
        return true;
      }

      // var center_bir = bir.substring(6, 7);
      // if (center_bir != "-") {
      //   console.log("err");
      // } else {
      //   console.log("succ");
      // }

      // var back_bir = bir.substring(7, 8);
      // var back_re = /^[a-zA-Z0-9]+$/;
      // if (!back_re.test(back_bir)) {
      //   console.log("err");
      // } else {
      //   console.log("succ");
      // }
    },
  },
  watch: {
    emailselectcompute() {
      this.emil_index = null;
    },
  },
  computed: {
    ...mapGetters(["service"]),
    emailselectcompute() {
      return this.list.filter((v) => {
        if (this.email.includes("@")) {
          return v
            .toLowerCase()
            .includes(this.email.toLowerCase().split("@")[1]);
        } else return v;
      });
    },
  },
  components: { captchaview },
};
</script>
